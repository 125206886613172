import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CrewClaimTypeEnum,
  crewClaimTypeEnumPluralizedName,
} from 'corso-types';
import { ChangeEvent, FormEventHandler, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import Alert from '~/components/Alert';
import Button from '~/components/Button';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import ContentWrapper from '~/components/ContentWrapper';
import { Checkbox, SupportingText, TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import Modal from '~/components/Modal';
import ReasonList from '~/components/ReasonList';
import Separator from '~/components/Separator';
import Skeleton from '~/components/Skeleton';
import { Badge } from '~/components/ui/primitives/Badge';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  useClaimReasonGroupDelete,
  useClaimReasonGroups,
  useClaimReasonGroupUpsert,
} from '~/hooks/useClaimReasonGroups';
import { useClaimReasons, useClaimReasonUpsert } from '~/hooks/useClaimReasons';
import { useProductGroups } from '~/hooks/useProductGroups';
import {
  ClaimReason,
  ClaimReasonGroup,
  ClaimReasonGroupCreate,
  claimReasonGroupCreate,
  ClaimReasonGroupUpdate,
  claimReasonGroupUpdate,
  UpsertFormProps,
} from '~/types';

function ClaimReasonCheckbox({ reason }: { reason: ClaimReason }) {
  const { control } = useFormContext<ClaimReasonGroup>();
  const {
    field: { onChange, value: selectedReasons },
  } = useController({
    control,
    name: 'claimReasons',
  });

  const checked = useMemo(
    () => selectedReasons.some((r) => r.id === reason.id),
    [selectedReasons, reason],
  );
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(
      event.target.checked ?
        [...selectedReasons, reason]
      : selectedReasons.filter((r) => r.id !== reason.id),
    );
  };
  return (
    <Checkbox
      className="ml-auto h-6 w-6"
      value={reason.name}
      checked={checked}
      onChange={handleChange}
      aria-label={reason.name} // visually the reason list list item is providing the label, but don't access to it here
    />
  );
}

function ClaimReasonGroupForm({
  show: showForm,
  values: group,
  onSubmit,
  onClose,
}: UpsertFormProps<ClaimReasonGroupUpdate, ClaimReasonGroupCreate>) {
  const isDefaultReasonGroup = group?.isDefault ?? false;
  const schemaToUse =
    group?.id ? claimReasonGroupUpdate : claimReasonGroupCreate;
  const { data: reasons = [] } = useClaimReasons();
  const { data: productGroups = [] } = useProductGroups();

  const methods = useForm({
    resolver: zodResolver(schemaToUse, {
      errorMap: (error, ctx) => {
        const message =
          (
            error.path.includes('claimReasons') &&
            error.code === z.ZodIssueCode.too_small
          ) ?
            'At least one claim reason must be selected.'
          : ctx.defaultError;

        return { message };
      },
    }),
    defaultValues: {
      claimReasons: [],
      isDefault: false,
    },
    values: group ?? undefined,
  });

  const submitHandler: FormEventHandler = (event) => {
    methods
      .handleSubmit((values) => {
        /**
         * typescript is having a hard time with distinguishing between the
         * two types of groups, so we'll just do a manual check here
         */
        if (group) {
          onSubmit(claimReasonGroupUpdate.parse(values));
          methods.reset();
          return;
        }
        onSubmit(claimReasonGroupCreate.parse(values));
        methods.reset();
      })(event)
      .catch(console.error);
  };

  return (
    <Modal
      show={showForm}
      title={group ? group.name : 'Add New Group'}
      description="Select the reasons that should be included in this group."
      onClose={onClose}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form className="flex flex-col gap-4" onSubmit={submitHandler}>
          {!isDefaultReasonGroup && (
            <>
              <TextInput
                id="name"
                label="Group Name"
                required
                {...methods.register('name')}
                error={methods.formState.errors.name?.message}
              />

              <Separator />

              <Controller
                name="claimType"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <SimpleSelect
                    label="Claim Type"
                    options={Object.values(CrewClaimTypeEnum).map(
                      (claimType) => ({
                        label: crewClaimTypeEnumPluralizedName[claimType],
                        value: claimType,
                      }),
                    )}
                    value={value}
                    onChange={onChange}
                    details="What claim type should this reason group be applied to?"
                    error={methods.formState.errors.claimType?.message}
                  />
                )}
              />

              <Separator />

              <Controller
                name="storeProductGroup"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <SimpleSelect
                    label="Product Group"
                    options={productGroups.map(({ id, name }) => ({
                      label: name,
                      value: `${id}`,
                    }))}
                    value={`${value?.id ?? ''}`} // always controlled, even if none selected
                    onChange={(selected) => {
                      const selectedId = Number.parseInt(selected, 10);
                      onChange(
                        productGroups.find(({ id }) => id === selectedId),
                      );
                    }}
                    details={
                      <p>
                        Select the product group that this reason group should
                        be applied to, groups can be configured{' '}
                        <Link
                          className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
                          to="../product-groups"
                        >
                          here
                        </Link>
                      </p>
                    }
                    error={methods.formState.errors.storeProductGroup?.message}
                  />
                )}
              />
            </>
          )}
          <Separator />
          <ReasonList
            dataTestId="reason-list-select"
            className="max-h-[26rem] overflow-y-auto"
            reasons={reasons}
            Action={ClaimReasonCheckbox}
          />
          <SupportingText error>
            {methods.formState.errors.claimReasons?.message}
          </SupportingText>

          <Button variant="primary" type="submit">
            Save
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
}

function DeleteGroupAction({ group }: { group: ClaimReasonGroup }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { mutate: deleteClaimReasonGroup } = useClaimReasonGroupDelete();

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const openConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const onConfirm = () => {
    deleteClaimReasonGroup(group);
    closeDeleteConfirmation();
  };

  return (
    <>
      <IconAction.Button
        icon={TrashIcon}
        title={`Delete ${group.name} group`}
        onClick={openConfirmation}
      />
      <ConfirmModal
        title="Delete Group"
        prompt={`Are you sure you want to delete ${group.name}?`}
        confirmText="Delete"
        cancelText="Cancel"
        show={showDeleteConfirmation}
        onConfirm={onConfirm}
        onCancel={closeDeleteConfirmation}
      />
    </>
  );
}

function EditGroupAction({ group }: { group: ClaimReasonGroup }) {
  const [showForm, setShowForm] = useState(false);
  const { mutate: saveClaimReasonGroup } = useClaimReasonGroupUpsert();

  const closeForm = () => {
    setShowForm(false);
  };

  const openForm = () => {
    setShowForm(true);
  };

  const saveUpdates = (updates: ClaimReasonGroupUpdate) => {
    saveClaimReasonGroup(updates);
    closeForm();
  };

  return (
    <>
      <IconAction.Button
        title={`Edit ${group.name} group`}
        icon={PencilIcon}
        onClick={openForm}
      />
      <ClaimReasonGroupForm
        show={showForm}
        values={group}
        onSubmit={saveUpdates}
        onClose={closeForm}
      />
    </>
  );
}

function AddGroupAction() {
  const [showForm, setShowForm] = useState(false);
  const { mutate: createClaimReasonGroup } = useClaimReasonGroupUpsert();
  const { data: productGroups = [] } = useProductGroups();
  const disable = productGroups.length === 0;

  const closeForm = () => {
    setShowForm(false);
  };

  const openForm = () => {
    setShowForm(true);
  };

  const createGroup = (group: ClaimReasonGroupCreate) => {
    createClaimReasonGroup(group);
    closeForm();
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={openForm}
        className="md:max-w-fit"
        disabled={disable}
      >
        <PlusIcon className="h-5 w-5" aria-hidden="true" />
        Add Group
      </Button>
      {disable && (
        <Alert
          message={
            <>
              You must create a Product Group before you can create a new Reason
              Group.{' '}
              <Link
                className="text-sm text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
                to="../product-groups"
              >
                Create Product Group
              </Link>
            </>
          }
        />
      )}
      <ClaimReasonGroupForm
        show={showForm}
        onSubmit={createGroup}
        onClose={closeForm}
      />
    </>
  );
}

const englishReasonCountPlurals: Readonly<Record<Intl.LDMLPluralRule, string>> =
  {
    zero: 'reasons',
    one: 'reason',
    two: 'reasons',
    few: 'reasons',
    many: 'reasons',
    other: 'reasons',
  } as const;
const englishCardinalRule = new Intl.PluralRules('en', { type: 'cardinal' });

function ClaimReasonGroupDisplay({ group }: { group: ClaimReasonGroup }) {
  return (
    <li className="flex justify-between font-medium">
      <div>
        <div>
          {group.name}
          <Badge className="ml-2">{group.claimType}</Badge>
        </div>
        <div className="mt-1 text-xs font-normal text-corso-gray-500">
          {`${group.claimReasons.length} ${
            englishReasonCountPlurals[
              englishCardinalRule.select(group.claimReasons.length)
            ]
          } selected`}
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <EditGroupAction group={group} />
        {!group.isDefault && <DeleteGroupAction group={group} />}
      </div>
    </li>
  );
}

function ReasonGroupSkeleton() {
  return (
    <div className="flex flex-row items-center justify-between gap-2">
      <Skeleton.Rectangle width="100%" height="45px" />
      <Skeleton.Rectangle width="45px" height="45px" />
      <Skeleton.Rectangle width="45px" height="45px" />
    </div>
  );
}

function ReasonListSkeleton() {
  return <Skeleton.Rectangle width="100%" height="52px" />;
}

export default function ClaimReasons() {
  const { data: claimReasons = [], isLoading: areReasonsLoading } =
    useClaimReasons();
  const { mutate: saveClaimReason } = useClaimReasonUpsert();
  const { data: groups = [], isLoading: areGroupsLoading } =
    useClaimReasonGroups();

  return (
    <ContentWrapper>
      <Card>
        <Card.Title as="h2">Reason Groups</Card.Title>
        <Card.Description>
          Reason Groups are used to organize which reasons are offered to
          customers during the Return or Warranty claim process.
        </Card.Description>
        <Skeleton
          instances={1}
          skeleton={ReasonGroupSkeleton}
          isLoading={areGroupsLoading}
        >
          <ul data-testid="reason-group-list" className="space-y-4">
            {groups.map((group) => (
              <ClaimReasonGroupDisplay key={group.id} group={group} />
            ))}
          </ul>

          <AddGroupAction />
        </Skeleton>
      </Card>
      <Card>
        <Card.Title as="h2">Reasons</Card.Title>
        <Card.Description>
          Add or remove reasons from the above groups to offer as options during
          the claim process, the same reason can be added to multiple groups.
        </Card.Description>
        <Skeleton
          instances={1}
          skeleton={ReasonListSkeleton}
          isLoading={areReasonsLoading}
        >
          <ReasonList
            dataTestId="editable-reason-list"
            editable
            reasons={claimReasons}
            onChange={saveClaimReason}
          />
        </Skeleton>
      </Card>
    </ContentWrapper>
  );
}
