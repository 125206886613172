import { faLightbulb, faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { SparklesIcon as SparklesIconSolid } from '@heroicons/react/20/solid';
import {
  ChartPieIcon,
  ExclamationCircleIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useQuery } from '@tanstack/react-query';
import {
  ClickhouseDataset,
  datasetDetails,
  enumToZodLiteralUnion,
} from 'corso-types';
import { Key, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import api from '~/api';
import Button from '~/components/Button';
import ButtonGroup from '~/components/ButtonGroup';
import Feed from '~/components/Feed';
import { TextInput } from '~/components/field';
import Icon from '~/components/Icon';
import IconAction from '~/components/IconAction';
import InfoPopover from '~/components/InfoPopover';
import Page from '~/components/Page';
import RelativeDateTime from '~/components/RelativeDateTime';
import { Badge } from '~/components/ui/primitives/Badge';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  useDashboardQuery,
  useLoadDashboardOptions,
} from '~/hooks/useDashboardQuery';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import { useStoreId } from '~/hooks/useStoreId';
import { useMerchantContext } from '~/providers/MerchantProvider';

// TODO: future improvements
// TODO: dynamic set height on iFrame

type InsightRequest = {
  id: Key;
  dataset: `${ClickhouseDataset}`;
  question: string;
  askedAt: Date;
};

// ------ Corso AI ------
function InsightResponse({ request }: { request: InsightRequest }) {
  const { userFullName } = useMerchantContext();
  const storeId = useStoreId();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['insight', storeId, request, userFullName],
    queryFn: () =>
      api.store(storeId).dashboard.prompt(
        {
          question: request.question,
          dataset: request.dataset,
        },
        userFullName,
      ),
    throwOnError: false,
  });

  if (isLoading)
    return (
      <div role="status">
        <Icon
          icon={faSpinnerThird}
          className="h-8 w-8 animate-spin text-corso-blue-600"
        />
        <span className="sr-only">Loading...</span>
      </div>
    );
  if (isError) return <div className="text-red-500">{error.message}</div>;
  if (!data)
    return (
      <p>
        {' '}
        Nothing was returned for that question, please rephrase and try again.
      </p>
    );
  return (
    <div className="flex justify-between rounded-md border p-2">
      <pre className="max-w-prose self-center text-wrap font-sans text-sm text-corso-gray-800">
        {data.answer}
      </pre>
      <Badge className="self-end">
        {datasetDetails[request.dataset].displayName}
      </Badge>
    </div>
  );
}

function SamplePromptButton({
  onClick,
  label,
}: {
  onClick: () => void;
  label: string;
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex size-full flex-col justify-between gap-3 rounded-lg bg-gradient-to-br from-corso-blue-400 via-corso-blue-500 to-corso-blue-600 p-4 hover:opacity-90"
    >
      <p className="text-pretty text-left text-sm font-semibold text-white">
        {label}
      </p>
      <Icon
        icon={faLightbulb}
        className="h-auto max-h-6 self-end text-white md:max-h-6"
      />
    </button>
  );
}

const promptFields = z.object({
  question: z.string().trim(),
  dataset: enumToZodLiteralUnion(ClickhouseDataset),
});

function getEnabledDatasets({
  isReturnsEnabled,
  isShippingProtectionEnabled,
}: {
  isReturnsEnabled: boolean;
  isShippingProtectionEnabled: boolean;
}) {
  const enabledDatasets: ClickhouseDataset[] = [];

  // applies to all merchants
  enabledDatasets.push(ClickhouseDataset.storeOrders);
  enabledDatasets.push(ClickhouseDataset.storeProductVariants);

  if (isReturnsEnabled) {
    enabledDatasets.push(ClickhouseDataset.crewClaimLineItems);
  }

  if (isShippingProtectionEnabled) {
    enabledDatasets.push(ClickhouseDataset.shipProtectClaims);
  }

  return enabledDatasets;
}

function PromptedInsight() {
  const storeId = useStoreId();
  const [questionsAndResponses, setQuestionAndResponses] = useState<
    InsightRequest[]
  >([]);

  useEffect(() => {
    setQuestionAndResponses([]);
  }, [storeId]);

  const { isReturnsEnabled, isShippingProtectionEnabled } =
    useEnabledClaimType();

  const enabledDatasets = getEnabledDatasets({
    isReturnsEnabled,
    isShippingProtectionEnabled,
  });

  const { control, register, handleSubmit, resetField, watch } = useForm<
    z.infer<typeof promptFields>
  >({
    resolver: zodResolver(promptFields),
    defaultValues: {
      dataset: ClickhouseDataset.storeOrders,
    },
  });

  const selectedDataset = watch('dataset');

  const [samplePrompts, setSamplePrompts] = useState(
    datasetDetails[selectedDataset].sampleQuestions,
  );

  const [placeholder, setPlaceholder] = useState(samplePrompts[0]);

  useEffect(() => {
    const updatedPrompts = datasetDetails[selectedDataset].sampleQuestions;
    setSamplePrompts(updatedPrompts);
    setPlaceholder(updatedPrompts[0]);
  }, [selectedDataset]);

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-[1fr_auto]">
      <div className="flex flex-col gap-4 rounded-xl rounded-t-none bg-white p-4 shadow-md md:rounded-tr-xl">
        <p>
          <span className="flex items-center gap-2">
            <span className="bg-gradient-to-br from-corso-blue-400 via-corso-blue-500 to-corso-blue-800 bg-clip-text text-2xl font-bold text-transparent">
              Corso AI
            </span>
            <Badge variant="info">Beta</Badge>
          </span>
          <small className="text-xs text-corso-gray-500">
            Ask a question to get insights from your data, start with a sample
            prompt or create your own.
          </small>
        </p>

        <ul
          style={{
            '--gap': '0.5rem', // aka gap-2
          }}
          className="flex flex-wrap gap-[--gap]"
        >
          {samplePrompts.map((prompt) => (
            <li
              key={prompt}
              className="flex-grow basis-[calc(50%-var(--gap))] md:flex-grow-0 md:basis-[calc(33.33%-var(--gap))]"
            >
              <SamplePromptButton
                label={prompt}
                onClick={() => {
                  resetField('question');
                  setPlaceholder(prompt);
                  // then fire the submit
                  setQuestionAndResponses((current) => [
                    ...current,
                    {
                      id: crypto.randomUUID(),
                      askedAt: new Date(),
                      question: prompt,
                      dataset: selectedDataset,
                    },
                  ]);
                }}
              />
            </li>
          ))}
        </ul>

        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => {
            handleSubmit(({ question, dataset }) => {
              resetField('question');
              setQuestionAndResponses((current) => [
                ...current,
                {
                  id: crypto.randomUUID(),
                  askedAt: new Date(),
                  question: placeholder ? question || placeholder : question,
                  dataset,
                },
              ]);
            })(e).catch((error) => console.error(error));
          }}
        >
          <TextInput
            id="question"
            label="Ask a Question for Data Insights"
            labelVisuallyHidden
            // value={placeholder}
            placeholder={placeholder}
            {...register('question')}
            // delegate enter key to prefill with placeholder if empty
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return;
              if (e.currentTarget.value || !placeholder) return;
              e.currentTarget.value = placeholder;
              // continue to submit after prefill
            }}
            addon={{
              insideEnd: (
                <IconAction.Button
                  title="Ask Question"
                  icon={SparklesIcon}
                  variant="ghost"
                  type="submit"
                />
              ),
            }}
            details={
              <p className="grid grid-cols-[auto_1fr] items-center gap-2 text-corso-gray-500 md:gap-1">
                <ExclamationCircleIcon className="h-4 w-4" />
                <span>
                  Responses may have mistakes, please verify important
                  information.
                </span>
              </p>
            }
          />
        </form>
        <Feed
          renderIcon={() => (
            <SparklesIconSolid className="h-4 w-4 text-corso-blue-600" />
          )}
          events={questionsAndResponses.sort(
            (a, b) => b.askedAt.valueOf() - a.askedAt.valueOf(),
          )}
        >
          {(event) => (
            <div className="flex flex-auto flex-col gap-1">
              <div className="flex flex-wrap items-center justify-between">
                <span className="text-sm text-corso-gray-500">
                  {event.question}
                </span>
                <div className="flex items-center gap-2">
                  <span className="text-xs leading-5 text-corso-gray-500">
                    asked <RelativeDateTime dateTime={event.askedAt} />
                  </span>
                </div>
              </div>

              <InsightResponse request={event} />
            </div>
          )}
        </Feed>
      </div>
      <div className="rounded-xl bg-white shadow-md">
        <div className="gap-2 p-4">
          <span className="flex items-center gap-2">
            <span className="bg-gradient-to-br from-corso-blue-400 via-corso-blue-500 to-corso-blue-800 bg-clip-text text-xl font-bold text-transparent">
              Dataset
            </span>
          </span>
          <small className="text-xs text-corso-gray-500">
            Select an available dataset.
          </small>
          <div className="mb-6 flex gap-2">
            <Controller
              control={control}
              name="dataset"
              render={({ field, fieldState }) => (
                <SimpleSelect
                  label="Dataset"
                  labelVisuallyHidden
                  options={enabledDatasets.map((dataset) => ({
                    value: `${dataset}` as const,
                    label: datasetDetails[dataset].displayName,
                  }))}
                  value={field.value}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </div>
          <span className="flex items-center gap-2">
            <span className="bg-gradient-to-br from-corso-blue-400 via-corso-blue-500 to-corso-blue-800 bg-clip-text text-xl font-bold text-transparent">
              Fields
            </span>
          </span>
          <small className="text-xs text-corso-gray-500">
            Fields available in the selected dataset.
          </small>
        </div>

        {!datasetDetails[selectedDataset].columns.length ?
          <p className="max-w-prose text-sm text-corso-gray-700">
            No column information available for the selected dataset.
          </p>
        : <ul>
            {datasetDetails[selectedDataset].columns
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map(({ displayName, description }) => (
                <li
                  key={displayName}
                  className="flex items-center gap-0.5 bg-white p-2 px-4 last:rounded-b-xl even:bg-corso-gray-100"
                >
                  <span className="text-sm text-corso-gray-700">
                    {displayName}
                  </span>
                  {!!description && (
                    // would probably look better in a tooltip style instead of a popover
                    <InfoPopover title={displayName}>
                      <InfoPopover.Prose>{description}</InfoPopover.Prose>
                    </InfoPopover>
                  )}
                </li>
              ))}
          </ul>
        }
      </div>
    </div>
  );
}

// ------ Dashboard ------
function LoadDashboard({ dashboardId }: { dashboardId: string }) {
  const iframeRef = useRef<HTMLDivElement>(null);

  const { data } = useDashboardQuery(dashboardId);
  const storeId = useStoreId();

  useEffect(() => {
    if (!data) {
      return;
    }

    const mountPoint = iframeRef.current;
    if (!mountPoint) {
      console.error('embedded dashboard mount point not found');
      return;
    }

    const embed = async () => {
      const { domain: supersetDomain, token } = data;
      await embedDashboard({
        id: dashboardId,
        supersetDomain,
        mountPoint,
        fetchGuestToken: () => Promise.resolve(token),
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: false,
          hideTab: false,
          filters: {
            visible: true,
            expanded: false,
          },
        },
      });
    };

    embed().catch((error) => {
      console.error('Error embedding dashboard', error);
    });
  }, [data, dashboardId, storeId]);

  return (
    <div
      ref={iframeRef}
      className="flex flex-col bg-[#F7F7F7] [&>iframe]:min-h-[2500px]"
    />
  );
}

function Dashboard() {
  const storeId = useStoreId();
  const { data } = useLoadDashboardOptions();
  const dashboards = data?.dashboards ?? [];

  const [selectedDashboard, setDashboard] = useState<
    (typeof dashboards)[number] | null
  >(null);

  const [defaultDashboard] = dashboards;
  if (defaultDashboard && !selectedDashboard) {
    setDashboard(defaultDashboard);
  }

  useEffect(() => {
    setDashboard(null);
  }, [storeId]);

  return (
    <div className="flex flex-auto flex-col gap-4 rounded-xl rounded-t-none bg-white p-4 shadow-md md:rounded-tr-xl">
      <div className="flex flex-col gap-6">
        {/* make the select list box go to the end of the div */}
        <div className="flex flex-row items-end">
          <SimpleSelect
            label="Select A Dashboard"
            labelVisuallyHidden
            options={dashboards.map((dashboard) => ({
              value: dashboard.id,
              label: dashboard.name,
            }))}
            value={selectedDashboard?.id}
            onChange={(id) =>
              setDashboard(dashboards.find((d) => d.id === id) ?? null)
            }
          />
        </div>

        {selectedDashboard && (
          <LoadDashboard dashboardId={selectedDashboard.id} />
        )}
      </div>
    </div>
  );
}

export default function Analytics() {
  const [view, setView] = useState(true);
  const toggleView = () => setView((prev) => !prev);

  return (
    <Page headline="Analytics">
      <div className="rounded-t-xl bg-white px-2 pb-1 pt-2 shadow-md md:w-min">
        <ButtonGroup>
          {/* // TODO for a11y, there is probably something necessary to better indicate these are "tab-like" and which one is active; i.e. with a radio input setup instead of buttons */}
          <Button
            variant={view ? 'primaryLight' : 'DEFAULT'}
            onClick={toggleView}
          >
            Corso AI
            <SparklesIcon className="h-5 w-5" />
          </Button>
          <Button
            variant={view ? 'DEFAULT' : 'primaryLight'}
            onClick={toggleView}
          >
            Dashboard
            <ChartPieIcon className="h-5 w-5" />
          </Button>
        </ButtonGroup>
      </div>
      <div className="flex flex-col gap-6">
        {view ?
          <PromptedInsight />
        : <Dashboard />}
      </div>
    </Page>
  );
}
