import { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/primitives/DropdownMenu';

// TODO consider renaming, or removing in favor of just `DropdownMenu` primitive
export default function Menu({
  buttonAs,
  children,
  align,
}: PropsWithChildren<{
  buttonAs: ReactNode;
  align?: ComponentProps<typeof DropdownMenuContent>['align'];
}>) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{buttonAs}</DropdownMenuTrigger>
      <DropdownMenuContent align={align}>{children}</DropdownMenuContent>
    </DropdownMenu>
  );
}

const baseMenuItemStyles =
  'flex items-center w-full gap-2 px-4 py-2 text-left text-sm text-corso-gray-800';

Menu.ItemButton = ({
  children,
  onClick,
}: PropsWithChildren<{ onClick: () => void }>) => (
  <DropdownMenuItem onClick={onClick} className={baseMenuItemStyles}>
    {children}
  </DropdownMenuItem>
);

type ItemNavLinkProps = Omit<ComponentProps<typeof NavLink>, 'className'>;

/** A `NavLink` wrapped within a `Menu.Item`. */
Menu.ItemNavLink = ({ children, ...props }: ItemNavLinkProps) => (
  <DropdownMenuItem asChild>
    <NavLink
      // * do not use the callback for `className`, it does not work as expected in this context and the function becomes serialized to a string instead of being run
      className={baseMenuItemStyles}
      // eslint-disable-next-line react/jsx-props-no-spreading -- intentionally spreading props to passthrough `NavLink` functionality
      {...props}
    >
      {children}
    </NavLink>
  </DropdownMenuItem>
);

type ItemLinkProps = Omit<ComponentProps<typeof Link>, 'className'>;

/** A `Link` wrapped within a `Menu.Item`. */
Menu.ItemLink = ({ children, ...props }: ItemLinkProps) => (
  <DropdownMenuItem asChild>
    <Link
      // * do not use the callback for `className`, it does not work as expected in this context and the function becomes serialized to a string instead of being run
      className={baseMenuItemStyles}
      // eslint-disable-next-line react/jsx-props-no-spreading -- intentionally spreading props to passthrough `NavLink` functionality
      {...props}
    >
      {children}
    </Link>
  </DropdownMenuItem>
);
