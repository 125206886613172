import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import { NumberInput, SwitchInput, UrlInput } from '~/components/field';
import FloatingSave from '~/components/FloatingSave';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
} from '~/hooks/useConfigSettings';
import {
  warrantySettingsFormSchema,
  WarrantySettingsFormValues,
} from '~/types';

const formId = 'warranty-settings';

export default function WarrantiesSettings() {
  const { data } = useConfigSettings(({ warranties }) => warranties);
  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,

    watch,
  } = useForm<WarrantySettingsFormValues>({
    resolver: zodResolver(warrantySettingsFormSchema),
    values: data,
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((values) => saveChanges({ warranties: values }))(event).catch(
      console.error,
    );
  };

  const isEnabled = watch('isWarrantiesEnabled');

  return (
    <form
      id={formId}
      onSubmit={submitHandler}
      onReset={(e) => {
        e.preventDefault();
        reset();
      }}
    >
      <FloatingSave
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        form={formId}
      />
      <Card>
        <div
          className={twMerge(
            'flex items-center justify-between gap-2',
            isEnabled && 'pb-2',
          )}
        >
          <Card.Title as="h2">Warranties</Card.Title>

          <Controller
            control={control}
            name="isWarrantiesEnabled"
            render={({ field: { onChange, value }, fieldState }) => (
              <SwitchInput
                id="warranties-offered"
                label="Warranties Offered"
                checked={!!value}
                onChange={(e) => {
                  onChange(e);
                }}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>

        {!isEnabled && (
          <Alert
            variant="info"
            title="Warranties are disabled"
            message="Settings can be configured, but warranties will not be offered to customers."
          />
        )}

        {/* // ? do we want an addon or require full URLs that are HTTPS; this would better with input masking */}
        <UrlInput
          id="warranty-policy-url"
          label="Policy URL"
          placeholder="https://company.com/warranties-policy"
          required
          {...register('warrantyPolicyUrl')}
          error={errors.warrantyPolicyUrl?.message}
        />
        <NumberInput
          id="warranty-validity-days"
          label="Warranty Window"
          placeholder="30"
          details="The number of days from the date an order was fulfilled that a customer can request a warranty review."
          required
          {...register('defaultWarrantyDays', {
            valueAsNumber: true,
          })}
          error={errors.isWarrantyInspectionEnabled?.message}
          addon={{
            insideEnd: 'days',
          }}
        />
        <SwitchInput // this is just for visual reference to the user
          defaultChecked
          disabled
          id="customer-image-required"
          label="Require Media Upload"
          details="Enforce that customers provide an image or video when submitting a warranty request."
        />

        <Controller
          control={control}
          name="isWarrantyInspectionEnabled"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="warranty-inspection-enabled"
              label="Inspections"
              details="Ability to add an inspection to a warranty request."
              checked={!!value}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />
      </Card>
    </form>
  );
}
