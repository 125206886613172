import { zodResolver } from '@hookform/resolvers/zod';
import { CrewIncentiveTypeEnum, CrewMerchantUi } from 'corso-types';
import { FormEventHandler } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import ClipboardButton from '~/components/ClipboardButton';
import {
  NumberInput,
  SwitchInput,
  TextInput,
  UrlInput,
} from '~/components/field';
import MonetaryRateInput from '~/components/field/MonetaryRateInput';
import FloatingSave from '~/components/FloatingSave';
import { Label } from '~/components/ui/primitives/Label';
import { useCurrency } from '~/hooks/useCurrency';
import {
  useReturnSettings,
  useReturnSettingsUpdate,
} from '~/hooks/useReturnSettings';
import { returnSettingsFormSchema, ReturnSettingsFormValues } from '~/types';

function ReturnsGeneralCard() {
  const {
    register,
    watch,
    formState: { errors },
    control,
  } = useFormContext<ReturnSettingsFormValues>();

  const isEnabled = watch('returns.isReturnsEnabled');

  return (
    <Card>
      <div
        className={twMerge(
          'flex items-center justify-between gap-2',
          isEnabled && 'pb-2',
        )}
      >
        <Card.Title as="h2">Returns</Card.Title>
        <Controller
          control={control}
          name="returns.isReturnsEnabled"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="returns-offered"
              label="Returns Offered"
              checked={!!value}
              onChange={(e) => {
                onChange(e);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>

      {!isEnabled && (
        <Alert
          variant="info"
          title="Returns are disabled"
          message="Settings can be configured, but returns will not be offered to customers."
        />
      )}
      <UrlInput
        id="return-policy-url"
        label="Return Policy URL"
        placeholder="https://company.com/returns-policy"
        {...register('returns.returnPolicyUrl')}
        error={errors.returns?.returnPolicyUrl?.message}
      />

      <Controller
        control={control}
        name="returns.isClaimImageFromCustomerRequired"
        defaultValue={false}
        render={({ field: { onChange, value }, fieldState }) => (
          <SwitchInput
            id="claim-image-from-customer-required"
            label="Require Media Upload"
            details="Enforce that customers provide an image or video when submitting a return request."
            checked={value}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="returns.isReturnInspectionEnabled"
        defaultValue={false}
        render={({ field: { onChange, value }, fieldState }) => (
          <SwitchInput
            id="return-inspection-enabled"
            label="Inspections"
            details="Ability to add an inspection to a return request."
            checked={value}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />

      <NumberInput
        id="return-expiration-days"
        label="Return Expiration Days"
        details="The number of days after the date the return was created that a customer can access the label or packing slip to return the item. "
        addon={{
          insideEnd: 'days',
        }}
        required
        {...register('returns.defaultReturnExpirationDays', {
          valueAsNumber: true,
        })}
        error={errors.returns?.defaultReturnExpirationDays?.message}
      />
    </Card>
  );
}

function ReturnsECommerceCard() {
  const {
    register,
    formState: { errors },
    getValues,
    control,
  } = useFormContext<ReturnSettingsFormValues>();

  return (
    <Card>
      <Card.Title as="h2">E-Commerce Platform</Card.Title>
      <Controller
        control={control}
        name="returns.isReplenishInventoryWhenReturned"
        defaultValue={false}
        render={({ field: { onChange, value }, fieldState }) => (
          <SwitchInput
            id="replenish-inventory-when-returned"
            label="Restock to Shopify"
            details="When a return is approved, inventory will be added back to the location from the corresponding Shipping Policy."
            checked={value}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />

      <TextInput
        id="product-tag-final-sale"
        label="Final Sale Product Tag"
        details="Apply this tag to any product that is ineligible for refunds, store credit, and exchanges."
        placeholder="Final Sale"
        required
        {...register('returns.productTagFinalSale')}
        error={errors.returns?.productTagFinalSale?.message}
        addon={{
          outsideEnd: (
            <ClipboardButton
              onClick={() => getValues('returns.productTagFinalSale')}
            />
          ),
        }}
      />
      <TextInput
        id="product-tag-exchange-only"
        label="Exchange Only Product Tag"
        details="Apply this tag to any product that only eligible for exchanges."
        placeholder="Exchange Only"
        required
        {...register('returns.productTagExchangeOnly')}
        error={errors.returns?.productTagExchangeOnly?.message}
        addon={{
          outsideEnd: (
            <ClipboardButton
              onClick={() => getValues('returns.productTagExchangeOnly')}
            />
          ),
        }}
      />
    </Card>
  );
}

function RefundsCard() {
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<ReturnSettingsFormValues>();
  const isEnabled = watch('refunds.isRefundsEnabled');

  return (
    <Card>
      <div className="flex items-center justify-between gap-2">
        <Card.Title as="h2">Refunds</Card.Title>
        <Controller
          control={control}
          name="refunds.isRefundsEnabled"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="refunds-offered"
              label="Refunds Offered"
              checked={value}
              onChange={(e) => {
                const { checked } = e.target;
                setValue('refunds.refundValidityDays', checked ? 30 : 0, {
                  shouldDirty: true,
                });
                onChange(e);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
      {isEnabled && (
        <>
          <NumberInput
            id="refund-validity-days"
            label="Refund Window"
            details="The number of days from the date an order was fulfilled that a customer can request a refund."
            addon={{
              insideEnd: 'days',
            }}
            required
            {...register('refunds.refundValidityDays', {
              valueAsNumber: true,
            })}
            error={errors.refunds?.refundValidityDays?.message}
          />

          <Label>Refund Handling Fee</Label>
          <Alert
            variant="DEFAULT"
            message="This feature has been moved to the Automations section, under Refund Handling Fee."
          />

          <Label>Deduct Shipping Cost from Refund Total</Label>
          <Alert
            variant="DEFAULT"
            message="This feature has been moved to the Automations section, under Deduct Shipping Cost from Refund Total."
          />
        </>
      )}
    </Card>
  );
}

function GiftCardsCard() {
  const { currencyCode, currencySymbol } = useCurrency();
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<ReturnSettingsFormValues>();
  const isEnabled = watch('giftCardsControl.isGiftCardEnabled');

  const incentiveType = watch('giftCards.giftCardIncentiveType');
  const incentiveAmount = watch('giftCards.giftCardIncentiveAmount');

  return (
    <Card>
      <div className="flex items-center justify-between gap-2">
        <Card.Title as="h2">Store Credit</Card.Title>
        <Controller
          control={control}
          name="giftCardsControl.isGiftCardEnabled"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="store-credit-offered"
              label="Store Credit Offered"
              checked={value}
              onChange={(e) => {
                const { checked } = e.target;
                setValue(
                  'giftCardsControl.giftCardValidityDays',
                  checked ? 30 : 0,
                  { shouldDirty: true },
                );
                onChange(e);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
      {isEnabled && (
        <>
          <NumberInput
            id="gift-card-validity-days"
            label="Store Credit Window"
            details="The number of days from the date an order was fulfilled that a customer can request store credit."
            addon={{
              insideEnd: 'days',
            }}
            required
            {...register('giftCardsControl.giftCardValidityDays', {
              valueAsNumber: true,
            })}
            error={errors.giftCardsControl?.giftCardValidityDays?.message}
          />
          <TextInput
            id="gift-card-product-id-from-platform"
            label="Store Credit Gift Card Product ID"
            details="The product ID of the gift card product that will be added to the order when a customer requests store credit."
            {...register('giftCards.giftCardProductIdFromPlatform')}
            error={errors.giftCards?.giftCardProductIdFromPlatform?.message}
          />
          <MonetaryRateInput
            id="gift-card-incentive-amount"
            label="Store Credit Incentive Amount"
            details="The amount that will be added to the store credit amount when a customer requests store credit."
            required
            currencyCode={currencyCode}
            currencySymbol={currencySymbol}
            value={{
              type:
                incentiveType !== CrewIncentiveTypeEnum.none ?
                  incentiveType
                : CrewIncentiveTypeEnum.fixed,
              amount: incentiveAmount,
            }}
            onChange={({ type, amount }) => {
              setValue('giftCards.giftCardIncentiveType', type, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              });
              setValue('giftCards.giftCardIncentiveAmount', amount, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              });
            }}
            error={errors.giftCards?.giftCardIncentiveAmount?.message}
          />
          <TextInput
            id="gift-card-order-prefix"
            label="Store Credit Order Prefix"
            details="This prefix will be added gift card orders."
            readOnly
            disabled
            required
            value={CrewMerchantUi.CREW_STORE_CREDIT_ORDER_PREFIX}
          />
        </>
      )}
    </Card>
  );
}

function VariantExchangesCard() {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<ReturnSettingsFormValues>();

  const isEnabled = watch('variantExchanges.isExchangeEnabled');

  return (
    <Card>
      <div className="flex items-center justify-between gap-2">
        <Card.Title as="h2">Variant Exchanges</Card.Title>
        <Controller
          control={control}
          name="variantExchanges.isExchangeEnabled"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="variant-exchanges-offered"
              label="Variant Exchanges Offered"
              checked={value}
              onChange={(e) => {
                const { checked } = e.target;
                setValue(
                  'variantExchanges.variantExchangeValidityDays',
                  checked ? 30 : 0,
                  { shouldDirty: true },
                );
                onChange(e);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </div>
      {isEnabled && (
        <>
          <NumberInput
            id="variant-exchange-validity-days"
            label="Exchange Window"
            details="The number of days from the date an order was fulfilled that a customer can request an exchange."
            addon={{
              insideEnd: 'days',
            }}
            required
            {...register('variantExchanges.variantExchangeValidityDays', {
              valueAsNumber: true,
            })}
            error={
              errors.variantExchanges?.variantExchangeValidityDays?.message
            }
          />
          <TextInput
            id="exchange-order-discount-code"
            label="Exchange Order Discount Code"
            details="The discount code used to do zero cost variant exchanges."
            placeholder="crew-discount"
            readOnly
            disabled
            required
            value={CrewMerchantUi.CREW_DISCOUNT_CODE}
          />
          <TextInput
            id="exchange-order-prefix"
            label="Exchange Order Prefix"
            details="This prefix will be added to orders created from variant exchanges. If no prefix is provided, the order number will be generated by the platform."
            placeholder="#"
            {...register('returns.exchangeOrderPrefix')}
            error={errors.returns?.exchangeOrderPrefix?.message}
          />
          <TextInput
            id="exchange-order-shipping-title"
            label="Shipping Title"
            details="The shipping title to use for exchange orders."
            {...register('variantExchanges.variantExchangeShippingTitle')}
            error={
              errors.variantExchanges?.variantExchangeShippingTitle?.message
            }
          />
          <NumberInput
            id="min-inventory-available-to-customer"
            label="Minimum Inventory Available to Customer"
            details="The minimum inventory available for a variant to be eligible for exchange; otherwise, the variant will be considered out of stock."
            required
            {...register('variantExchanges.minInventoryAvailableToCustomer', {
              valueAsNumber: true,
            })}
            error={
              errors.variantExchanges?.minInventoryAvailableToCustomer?.message
            }
          />
          <Controller
            control={control}
            name="variantExchanges.isExchangeOfExchangeAllowed"
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState }) => (
              <SwitchInput
                id="exchange-of-exchange-allowed"
                label="Allow Exchange of Exchange"
                details="When enabled, customers can create an exchange from a previously created exchange. The eligibility window will always be based on the original order."
                checked={value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </>
      )}
    </Card>
  );
}

const formId = 'returns-settings';
export default function ReturnsSettings() {
  const { data } = useReturnSettings();
  const { mutateAsync: saveConfigChanges } = useReturnSettingsUpdate();

  const methods = useForm<ReturnSettingsFormValues>({
    resolver: zodResolver(returnSettingsFormSchema),
    values: data,
  });
  const submitHandler: FormEventHandler = (event) => {
    methods
      .handleSubmit((values) => saveConfigChanges(values))(event)
      .catch(console.error);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form
        id={formId}
        className="flex flex-col gap-4"
        onSubmit={submitHandler}
        onReset={(e) => {
          e.preventDefault();
          methods.reset();
        }}
      >
        <FloatingSave
          isDirty={methods.formState.isDirty}
          isSubmitting={methods.formState.isSubmitting}
          form={formId}
        />
        <ReturnsGeneralCard />

        <ReturnsECommerceCard />
        <RefundsCard />
        <GiftCardsCard />
        <VariantExchangesCard />
      </form>
    </FormProvider>
  );
}
