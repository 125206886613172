import { CrewMerchantUi, isTruthy } from 'corso-types';
import DescriptionList from '~/components/DescriptionList';
import { customFieldValueTypeFormatter } from '~/components/FormatCustomField';

type CustomField = CrewMerchantUi.AppliedCustomField;

export default function ClaimLineItemDetails({
  claimLineItem,
  reviewLineItem,
}: {
  claimLineItem: {
    reason: {
      name: string;
      detail?: {
        name: string;
      };
    };
    comments?: string | null;
    customFields: CustomField[];
    noteToCustomer?: string;
  };
  reviewLineItem: {
    noteToCustomer?: string | null;
  };
}) {
  const descriptions = [
    {
      term: 'Reason',
      details: (
        <>
          {claimLineItem.reason.name}
          {claimLineItem.reason.detail?.name &&
            ` - ${claimLineItem.reason.detail.name}`}
        </>
      ),
    },
    !!claimLineItem.comments?.length && {
      term: 'Comment',
      details: claimLineItem.comments,
    },
    ...claimLineItem.customFields.map(({ displayName, valueType, value }) => ({
      term: displayName,
      details: customFieldValueTypeFormatter[valueType](value),
    })),
    !!claimLineItem.noteToCustomer?.length && {
      term: 'Note to Customer',
      details: claimLineItem.noteToCustomer,
    },
    !!reviewLineItem.noteToCustomer?.length && {
      term: 'Note to Customer',
      details: reviewLineItem.noteToCustomer,
    },
  ].filter(isTruthy);

  return (
    <DescriptionList descriptions={descriptions} size="xs" layoutType="list" />
  );
}
