import LineItem from '~/components/LineItem';
import LineItemList from '~/components/LineItemList';
import ResolutionLineItemTitle from './ReslutionLineItemTitle';
import { ReviewResolutionLineItemProps } from './types';

// basically the same as an `ExchangeOrderLineItem` but with a different name and image
export default function ExchangeOrderLineItem({
  replacementOrder,
  lineItems,
}: ReviewResolutionLineItemProps & {
  replacementOrder?: {
    nameFromPlatform: string;
    adminLink: string;
  };
}) {
  return (
    <div>
      <ResolutionLineItemTitle
        title="Replacement Order"
        orderHref={replacementOrder?.adminLink}
        orderNumber={replacementOrder?.nameFromPlatform}
      >
        <LineItem variant="no-image">
          <LineItemList lineItems={lineItems} />
        </LineItem>
      </ResolutionLineItemTitle>
    </div>
  );
}
