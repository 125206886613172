import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * A simple wrapper element to help provide consistent spacing between children in a flow-like context.
 * Provides a `className` prop to allow customization of the wrapper element; however, customizations should be limited to spacing and not layout.
 * The classes of this wrapper will override any classes provided by the `className` prop to keep it consistent.
 *
 * @deprecated Prefer to use `Card` for styling, or `flex flex-col gap-2` for layout.
 */
// ? consider a selection of components similar to the structural components from Shopify's checkout UI; https://shopify.dev/docs/api/checkout-ui-extensions/2024-07/components
export default function ContentWrapper({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    // * the order of these classes prevents overriding these default styles
    <div className={twMerge(className, 'flex flex-col gap-2')}>{children}</div>
  );
}
