import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler, ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import Button from '~/components/Button';
import Card from '~/components/Card';
import ClipboardButton from '~/components/ClipboardButton';
import { SupportingText, TextInput, UrlInput } from '~/components/field';
import SwitchInput from '~/components/field/SwitchInput';
import FloatingSave from '~/components/FloatingSave';
import Separator from '~/components/Separator';
import { Badge } from '~/components/ui/primitives/Badge';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
  useConfigureGsp,
} from '~/hooks/useConfigSettings';
import { useThemeSettings } from '~/hooks/useThemeSettings';
import {
  shipProtectSettingsFormSchema,
  ShipProtectSettingsFormValues,
} from '~/types';

function ShippingProtectionDisplay({
  imgUrl,
  title,
  description,
  action,
  className,
}: {
  imgUrl: string;
  title: string;
  description?: ReactNode;
  action?: ReactNode;
  className?: string;
}) {
  return (
    <section
      className={twMerge(
        'flex flex-col gap-4 rounded-lg border bg-white px-4 py-5 shadow sm:p-6',
        className,
      )}
    >
      <h4 className="sr-only">{title}</h4>
      <div className="flex items-center justify-between">
        <img src={imgUrl} alt={title} className="h-10 w-auto" aria-hidden />
        {action}
      </div>
      {description && (
        <>
          <Separator />
          <SupportingText>{description}</SupportingText>
        </>
      )}
    </section>
  );
}

const formId = 'shipping-protection-settings';
function ShippingProtectionForm() {
  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();
  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );
  const { data: themeSettings } = useThemeSettings();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
  } = useForm<ShipProtectSettingsFormValues>({
    resolver: zodResolver(shipProtectSettingsFormSchema),
    values: data,
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((values) => saveChanges({ shippingProtection: values }))(
      event,
    ).catch(console.error);
  };

  const reorderTemplateUrl =
    themeSettings?.customerAppUrl ?
      `${themeSettings.customerAppUrl}/reorder/{{order.id}}`
    : '';

  return (
    <form
      id={formId}
      onSubmit={submitHandler}
      onReset={(e) => {
        e.preventDefault();
        reset();
      }}
    >
      <FloatingSave
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        form={formId}
      />
      <Card>
        <UrlInput
          id="customer-portal"
          label="Reorder Template URL"
          details="Use this URL inside of Shopify liquid templates to direct users to the Reorder portal, this is typically used in order confirmation emails when a customer purchases Shipping Protection."
          value={reorderTemplateUrl}
          required
          readOnly
          disabled
          addon={{
            outsideEnd: (
              // using `grid` on mobile and `flex` on desktop avoid apply `flex-grow` to each child
              <div className="grid grid-cols-2 items-center gap-2 md:flex">
                <ClipboardButton onClick={() => reorderTemplateUrl} />
              </div>
            ),
          }}
        />

        <Controller
          control={control}
          name="isOffered"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="offer-shipping-protection"
              label="Offer Shipping Protection"
              details="When enabled, Shipping Protection is offered to customers."
              checked={!!value}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="isClaimImageFromCustomerRequired"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="claim-image-required"
              label="Require Images for Claims"
              details="When enabled, customers are required to upload an image when submitting a claim."
              checked={!!value}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="shouldSendPurchaseConfirmation"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="should-send-purchase-confirmation"
              label="Send Corso Shipping Protection Purchase Confirmation"
              details="When enabled, customers will receive an email confirmation from Corso after purchasing Shipping Protection."
              checked={!!value}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="giftCardProductIdFromPlatform"
          render={({ fieldState, field: { value, onChange } }) => (
            <TextInput
              id="gift-card-product-id-from-platform"
              label="Store Credit Gift Card Product ID"
              details="The product ID for the Corso team to use when generating gift cards for store credit."
              error={fieldState.error?.message}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </Card>
    </form>
  );
}

export default function ShippingProtectionSettings() {
  const { data: spSettings } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );
  const { mutate: configureSp, isPending } = useConfigureGsp();

  return (
    <div className="flex flex-col gap-4">
      <ShippingProtectionDisplay
        className="col-span-2"
        imgUrl="https://cdn.corso.com/img/gsp-vertical-c.png"
        title="Green Shipping Protection"
        description={
          spSettings?.isConfigured ?
            'Your customers are protected with Shipping Protection.'
          : `Enabling Shipping Protection will add a product to your inventory that will allow you to offer Shipping Protection to your customers. Someone from our team will reach out to assist you with the setup.`
        }
        action={
          spSettings?.isConfigured ?
            <Badge
              className="w-28 justify-center px-3 py-2 capitalize sm:w-40"
              variant="success"
            >
              ACTIVE
            </Badge>
          : <Button
              className="w-28 sm:w-40"
              variant="primary"
              loading={isPending}
              onClick={() => configureSp()}
            >
              {isPending ? 'Loading' : 'Enable'}
            </Button>
        }
      />
      {spSettings?.isConfigured && <ShippingProtectionForm />}
    </div>
  );
}
