import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';
import ResolutionLineItemTitle from './ReslutionLineItemTitle';
import { MonetaryResolutionLineItemProps } from './types';

export default function RefundLineItem({
  compareAmount,
  amount,
  handlingFee,
  shippingFee,
}: MonetaryResolutionLineItemProps & {
  handlingFee?: number;
  shippingFee?: number;
}) {
  const {
    storeUser: {
      store: { currencyCode },
    },
  } = useMerchantContext();

  return (
    <ResolutionLineItemTitle title="Refund">
      <div>
        <p className="flex justify-between gap-2 text-xs text-corso-gray-500">
          <span> Total</span>
          <div className="flex gap-1">
            {compareAmount !== undefined && compareAmount !== amount && (
              <span className="text-corso-gray-500 line-through">
                {formatter.currency(compareAmount, currencyCode)}
              </span>
            )}
            <span>{formatter.currency(amount, currencyCode)}</span>
          </div>
        </p>

        {handlingFee !== undefined && handlingFee > 0 && (
          <p className="flex justify-between gap-2 text-xs text-corso-gray-500">
            <span>Handling Fee</span>
            <span>{formatter.currency(handlingFee, currencyCode)}</span>
          </p>
        )}
        {shippingFee !== undefined && shippingFee > 0 && (
          <p className="flex justify-between gap-2 text-xs text-corso-gray-500">
            <span>Shipping Fee</span>
            <span>{formatter.currency(shippingFee, currencyCode)}</span>
          </p>
        )}
      </div>
    </ResolutionLineItemTitle>
  );
}
