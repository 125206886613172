import Menu from '~/components/Menu';

import {
  EnvelopeIcon,
  HomeIcon,
  PencilIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import { PropsWithChildren, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import api from '~/api';
import Address from '~/components/Address';
import Button from '~/components/Button';
import EmptyDetails from '~/components/claim/EmptyDetails';
import ConfirmModal from '~/components/ConfirmModal';
import ContentWrapper from '~/components/ContentWrapper';
import DescriptionList, { Description } from '~/components/DescriptionList';
import DetailInfoItem from '~/components/DetailInfoItem';
import { DateInput, SwitchInput, TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import LineItem from '~/components/LineItem';
import MediaGallery from '~/components/MediaGallery';
import Modal from '~/components/Modal';
import RelativeDateTime from '~/components/RelativeDateTime';

import Card from '~/components/Card';
import ProductReplacement from '~/components/claim/resolutionModifiers/ProductReplacement';

import DetailCard from '~/components/DetailCard';
import { customFieldValueTypeFormatter } from '~/components/FormatCustomField';
import { useStoreId } from '~/hooks/useStoreId';
import { queryClient } from '~/providers/QueryProvider';
import {
  RegistrationUpdate,
  registrationUpdate,
  SelectedVariant,
} from '~/types';
import { formatter } from '~/utils/formatter';

function RegistrationClaimSummary({
  storeId,
  claims,
}: {
  storeId: string;
  claims: CrewMerchantUi.Registration['claims'];
}) {
  return (
    claims.length > 0 &&
    claims.map((claim) => {
      const { externalId, claimType, id: claimId } = claim;

      return (
        <DetailCard
          key={claimId}
          linkTo={`/${storeId}/claims/${claimType}/${claimId}`}
          externalId={externalId}
          title={`${claimType} Claim`}
        />
      );
    })
  );
}

function EditRegistration({
  show,
  registration,
  onClose,
}: {
  registration: CrewMerchantUi.Registration;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();

  const queryKey = [
    'registration',
    {
      registrationId: `${registration.id}`,
      storeId,
    },
  ];

  const { mutate: updateRegistration, isPending } = useMutation({
    mutationFn: api.store(storeId).registrations.update,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const [showEditAddress, setEditAddress] = useState(!!registration.address);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    register,
  } = useForm<RegistrationUpdate>({
    values: {
      estimatedPurchaseDate: registration.estimatedPurchaseDate,
      address: registration.address,
    },
    resolver: zodResolver(registrationUpdate),
  });

  const newPurchaseDate = watch('estimatedPurchaseDate');

  const handleShowEditAddress = () => {
    setEditAddress(!showEditAddress);
    reset({ estimatedPurchaseDate: newPurchaseDate });
  };

  const closeAndReset = () => {
    onClose();
    reset();
    setEditAddress(false);
  };

  const formId = 'edit-registration-form';
  return (
    <Modal
      title="Edit Registration"
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={closeAndReset}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
            loading={isPending}
          >
            Save
          </Button>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((body) => {
          updateRegistration({
            registrationId: `${registration.id}`,
            body,
          });
          onClose();
        })}
        className="flex flex-col gap-4"
      >
        <Controller
          control={control}
          name="estimatedPurchaseDate"
          render={({ field, fieldState }) => (
            <DateInput
              id="estimated-purchase-date"
              label="Estimated Purchase Date"
              value={(field.value as string | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
              onChange={field.onChange}
              error={fieldState.error?.message}
              details={
                !(field.value as string | undefined) &&
                'Using Store Defaults for Warranty Reviews'
              } // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
            />
          )}
        />

        <SwitchInput
          id="is-estimated-purchase-date"
          label="Edit Address"
          onChange={handleShowEditAddress}
          checked={showEditAddress}
        />

        {showEditAddress && (
          <>
            <TextInput
              id="shipping-address-first-name"
              label="First Name"
              autoComplete="given-name"
              {...register('address.firstName')}
              error={errors.address?.line1?.message}
            />
            <TextInput
              id="shipping-address-last-name"
              label="Last Name"
              autoComplete="family-name"
              {...register('address.lastName')}
              error={errors.address?.line1?.message}
            />

            <TextInput
              id="shipping-address-line1"
              label="Street Address Line 1"
              autoComplete="address-line1"
              {...register('address.line1')}
              error={errors.address?.line1?.message}
            />

            <TextInput
              id="shipping-address-line2"
              label="Street Address Line 2"
              autoComplete="address-line2"
              {...register('address.line2')}
              error={errors.address?.line2?.message}
            />

            <TextInput
              id="shipping-address-city"
              label="City"
              autoComplete="address-level2"
              {...register('address.city')}
              error={errors.address?.city?.message}
            />

            <TextInput
              id="shipping-address-state-or-province"
              label="State or Province Code"
              autoComplete="address-level1"
              {...register('address.stateOrProvinceCode')}
              error={errors.address?.stateOrProvinceCode?.message}
            />

            <TextInput
              id="shipping-address-postal-code"
              label="Postal Code"
              autoComplete="postal-code"
              {...register('address.postalCode')}
              error={errors.address?.postalCode?.message}
            />

            <TextInput
              id="shipping-address-country"
              label="Country Code"
              autoComplete="country"
              {...register('address.countryCode')}
              error={errors.address?.countryCode?.message}
            />
          </>
        )}
      </form>
    </Modal>
  );
}

function DeleteRegistrationProduct({
  show,
  lineItemId,
  registrationId,
  onClose,
}: {
  lineItemId: number;
  registrationId: number;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();

  const queryKey = [
    'registration',
    {
      registrationId: `${registrationId}`,
      storeId,
    },
  ];

  const { mutate: updateRegistration } = useMutation({
    mutationFn: api.store(storeId).registrations.update,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const confirmDelete = () => {
    updateRegistration({
      registrationId: `${registrationId}`,
      body: {
        lineItemIdToRemove: lineItemId,
      },
    });
    onClose();
  };

  return (
    <ConfirmModal
      title="Delete Product"
      prompt="Are you sure you would like to remove this product from the Registration?"
      confirmText="Delete"
      cancelText="Cancel"
      show={show}
      onConfirm={confirmDelete}
      onCancel={onClose}
    />
  );
}

function RegistrationGeneralInfo({
  registration,
}: PropsWithChildren<{
  registration: CrewMerchantUi.Registration;
}>) {
  const { externalId, createdOn, registrationChannelName, claims } =
    registration;
  const [showEditRegistration, setShowEditRegistration] = useState(false);

  const isRegistrationEditable = claims.length === 0;

  return (
    <div className="grid grid-cols-1 gap-2 py-4 sm:gap-4 md:grid-cols-3">
      {/* Section for externalId, registrationChannelName, and createdOn */}
      <div className="col-span-1 flex flex-col items-start justify-center gap-2 md:col-span-3">
        <p className="text-lg font-semibold">#{externalId}</p>

        <span className="text-sm">
          <span>{registrationChannelName}</span>
        </span>
        <span className="text-xs text-corso-gray-500">
          Created <RelativeDateTime dateTime={createdOn} />
        </span>
      </div>

      {/* Section for the menu and EditRegistration */}
      <div className="flex items-start justify-end gap-2 sm:col-start-4 sm:row-start-1">
        {isRegistrationEditable && (
          <ContentWrapper className="w-full sm:w-auto">
            <Menu
              align="end"
              buttonAs={
                <IconAction.Button
                  icon={EllipsisVerticalIcon}
                  title="More Actions"
                  className="bg-white text-corso-gray-800"
                />
              }
            >
              <Menu.ItemButton onClick={() => setShowEditRegistration(true)}>
                <PencilIcon className="h-5 w-5" /> Edit Registration
              </Menu.ItemButton>
            </Menu>
          </ContentWrapper>
        )}

        <EditRegistration
          registration={registration}
          show={showEditRegistration}
          onClose={() => setShowEditRegistration(false)}
        />
      </div>
    </div>
  );
}

function RegistrationCustomerDetail({
  registration,
}: {
  registration: CrewMerchantUi.Registration;
}) {
  const { email, firstName, lastName, address } = registration;

  return (
    <Card key={registration.id}>
      <div className="space-y-3">
        <Card.Title>Customer</Card.Title>

        {/* customer name */}
        <DetailInfoItem
          icon={<UserIcon className="h-4 w-4" />}
          content={`${firstName} ${lastName}`}
        />

        {/* customer email */}
        <DetailInfoItem
          icon={<EnvelopeIcon className="h-4 w-4" />}
          content={email}
        />

        {/* customer address */}
        {address && (
          <DetailInfoItem
            icon={<HomeIcon className="h-4 w-4" />}
            className="items-start"
            content={<Address address={address} />}
          />
        )}
      </div>
    </Card>
  );
}

function RegistrationRequest({
  registration,
}: {
  registration: CrewMerchantUi.Registration;
}) {
  const {
    estimatedPurchaseDate,
    lineItems,
    proofOfPurchaseFileUrls,
    registrationChannelName,
    createdOn,

    id: registrationId,
    customFields,
  } = registration;

  const descriptions: Description[] = [];

  descriptions.push({
    details: registrationChannelName,
    term: 'Channel',
  });

  if (estimatedPurchaseDate) {
    descriptions.push({
      details: formatter.date(estimatedPurchaseDate),
      term: 'Estimated Purchase Date',
    });
  }

  customFields?.forEach(({ displayName, valueType, value }) => {
    descriptions.push({
      term: displayName,
      details: customFieldValueTypeFormatter[valueType](value),
    });
  });

  descriptions.push({
    details: <RelativeDateTime dateTime={createdOn} />,
    term: 'Created',
  });

  if (proofOfPurchaseFileUrls.length) {
    descriptions.push({
      term: 'Proof Of Purchase',
      details: (
        <MediaGallery
          media={proofOfPurchaseFileUrls.map((url) => ({
            src: url,
            alt: 'Proof of Purchase',
          }))}
        />
      ),
    });
  }

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [replacementItems, setReplacementItems] = useState<SelectedVariant[]>(
    [],
  );

  const storeId = useStoreId();

  const queryKey = [
    'registration',
    {
      registrationId: `${registration.id}`,
      storeId,
    },
  ];

  const { mutate: updateRegistration, isPending } = useMutation({
    mutationFn: api.store(storeId).registrations.update,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const handleSave = () => {
    updateRegistration({
      registrationId: `${registrationId}`,
      body: {
        storeProductVariants: replacementItems.map((item) => ({
          idFromPlatform: item.idFromPlatform,
          quantity: item.quantity,
        })),
      },
    });
    setReplacementItems([]);
  };

  const isRegistrationEditable = registration.claims.length === 0;

  return (
    <>
      <Card>
        <Card.Title>Registration Request Details</Card.Title>

        <DescriptionList size="xs" descriptions={descriptions} />
      </Card>

      <Card>
        <Card.Title>Products</Card.Title>

        {lineItems?.map((li) => (
          <div className="flex justify-between" key={li.id}>
            <LineItem
              variant="DEFAULT"
              key={li.id}
              imageUrl={li.imgUrl}
              sku={li.sku}
              name={li.name}
              quantity={li.quantity}
              options={li.optionsFromPlatform}
            />

            {isRegistrationEditable && (
              <div>
                <IconAction.Button
                  icon={TrashIcon}
                  title="Remove Product"
                  onClick={() => setShowDeleteConfirmation(true)}
                />
              </div>
            )}

            <DeleteRegistrationProduct
              lineItemId={li.id}
              registrationId={registrationId}
              show={showDeleteConfirmation}
              onClose={() => setShowDeleteConfirmation(false)}
            />
          </div>
        ))}
      </Card>

      {isRegistrationEditable && (
        <Card>
          <div className="flex items-center justify-between">
            <Card.Title>Add Products</Card.Title>
            {replacementItems.length > 0 && (
              <Button
                title="Save Replacement Products"
                variant="primary"
                loading={isPending}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            )}
          </div>
          <div className="flex flex-col">
            <ProductReplacement
              products={replacementItems}
              onChange={setReplacementItems}
              originalProductName=""
              buttonText="Add Product"
            />
          </div>
        </Card>
      )}
    </>
  );
}

export default function RegistrationOverview() {
  const { registrationId } = useParams();
  const storeId = useStoreId();

  const {
    data: registration,
    isLoading,
    isError,
  } = useQuery({
    enabled: !!registrationId,
    queryKey: ['registration', { registrationId, storeId }],
    queryFn: () => {
      if (!registrationId) throw new Error('Missing Registration ID');
      return api.store(storeId).registrations.get({
        registrationId,
      });
    },
    retry: 1,
    throwOnError: false,
    refetchOnMount: true,
  });

  if (!registration || isLoading)
    return (
      <EmptyDetails
        claimType="Registration"
        title="Loading Registration"
        description="Please wait while we load the registration."
      />
    );

  if (isError)
    return (
      <EmptyDetails
        claimType="Registration"
        title="Error Loading Registration"
        description="Please try again later."
      />
    );

  const { claims } = registration;

  return (
    <>
      <RegistrationGeneralInfo registration={registration} />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-[3fr_1fr]">
        <div className="space-y-4">
          <RegistrationRequest registration={registration} />
        </div>
        <div className="order-first space-y-4 md:order-none">
          <RegistrationClaimSummary storeId={storeId} claims={claims} />
          <RegistrationCustomerDetail registration={registration} />
        </div>
      </div>
    </>
  );
}
