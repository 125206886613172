import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import {
  CrewClaimRollupStatusCode,
  CrewClaimTypeEnum,
  crewClaimTypeEnumPluralizedName,
  SearchableCrewClaimResolutionMethod,
} from 'corso-types';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useMemo } from 'react';
import { Outlet, useOutletContext, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import api from '~/api';
import BackAction from '~/components/BackAction';
import ClaimsList from '~/components/claim/ClaimList';
import ClaimSearch from '~/components/claim/ClaimSearch';
import ContentWrapper from '~/components/ContentWrapper';
import Page from '~/components/Page';
import useIsDesktop from '~/hooks/useIsDesktop';
import { usePathParams } from '~/hooks/usePathParams';

type ClaimsLayoutContext = {
  claimType: CrewClaimTypeEnum;
};

/** Only available to direct descendants of the `AppLayout`. */
export function useClaimsLayoutContext() {
  return useOutletContext<ClaimsLayoutContext>();
}

const searchParamsSchema = z.preprocess(
  (input) =>
    input instanceof URLSearchParams ?
      [...input.entries()].reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {},
      )
    : input,
  z.object({
    searchTerm: z.string().default(''),
    startDate: z.coerce.date().default(subDays(new Date(), 30)),
    endDate: z.coerce.date().default(new Date()),
    requestedResolutionMethod: z
      .nativeEnum(SearchableCrewClaimResolutionMethod)
      .or(z.literal('all'))
      .default('all'),
    rollupStatusCode: z
      .nativeEnum(CrewClaimRollupStatusCode)
      .or(z.literal('all'))
      .default('all'),
    orderBy: z.union([z.literal('asc'), z.literal('desc')]).default('desc'),
  }),
);

const paramsSchema = z.object({
  storeId: z.string(),
  claimType: z
    .string()
    .transform((value) => value.replace(/^./, (s) => s.toUpperCase()))
    .pipe(z.nativeEnum(CrewClaimTypeEnum)),
  claimId: z.string().optional(),
});

/** Represents a sidebar and main content layout, which intelligently shows both on desktop, while separating the sidebar list navigation and individual items on mobile. */
export default function ClaimsLayout() {
  const [searchParams] = useSearchParams();
  const params = usePathParams(paramsSchema);
  const searchData = useMemo(
    () => searchParamsSchema.parse(searchParams),
    [searchParams],
  );

  /** Debounce data, so that the query parameter and derived state can be updated in real time, but the query key can be debounced. */
  const debouncedSearchData = useDebounce(searchData, 500);

  // TODO paginated/virtualized
  // TODO loading skeleton / disabled state of form while query loading
  // TODO error state
  const { data: claimSearchResults, isLoading } = useQuery({
    refetchOnMount: true,
    throwOnError: false,
    queryKey: ['claims', params.storeId, params.claimType, debouncedSearchData],
    queryFn: async () => {
      const {
        searchTerm,
        startDate,
        endDate,
        rollupStatusCode,
        orderBy,
        requestedResolutionMethod,
      } = debouncedSearchData;

      const kind = params.claimType === 'Return' ? 'Return' : 'Warranty';

      const claimList = await api.store(params.storeId).search({
        kind,
        orderBy,
        startDate: startOfDay(startDate).toISOString(),
        endDate: endOfDay(endDate).toISOString(),
        searchTerm: searchTerm.length ? searchTerm : undefined,
        requestedResolutionMethods:
          requestedResolutionMethod === 'all' ? undefined : (
            [requestedResolutionMethod]
          ),
        statusCodes:
          rollupStatusCode === 'all' ? undefined : [rollupStatusCode],
      });

      if (claimList.kind !== kind) {
        throw new Error('Invalid kind');
      }

      return claimList;
    },
    retry: 3,
  });

  const isDesktop = useIsDesktop();
  const headline = crewClaimTypeEnumPluralizedName[params.claimType];
  const context = useMemo<ClaimsLayoutContext>(
    () => ({ claimType: params.claimType }),
    [params.claimType],
  );

  const claims = claimSearchResults?.data ?? [];

  if (!isDesktop) {
    return (
      <Page headline={headline}>
        <ContentWrapper>
          {params.claimId ?
            <>
              <BackAction.Link text="Claims" />
              <div className="px-2">
                <Outlet context={context} />
              </div>
            </>
          : <>
              <ClaimSearch
                searchTerm={searchData.searchTerm}
                dateRange={{
                  from: searchData.startDate,
                  to: searchData.endDate,
                }}
                requestedResolutionMethod={searchData.requestedResolutionMethod}
                rollupStatusCode={searchData.rollupStatusCode}
                orderBy={searchData.orderBy}
              />
              <ClaimsList
                claims={claimSearchResults?.data}
                isLoading={isLoading}
              />
            </>
          }
        </ContentWrapper>
      </Page>
    );
  }

  // desktop layout with list and details in a multi-column layout
  return (
    <Page headline={headline}>
      <ContentWrapper>
        <div className="grid grid-cols-4 items-start gap-4">
          {/*
           * // * top-20 is a magic value so that it doesn't slip behind the app layout navigation
           * // * z index is required to ensure any absolute positioned elements such as the date picker are above the app main content
           */}
          <ContentWrapper className="sticky top-20 z-10 w-full self-start">
            <ClaimSearch
              requestedResolutionMethod={searchData.requestedResolutionMethod}
              searchTerm={searchData.searchTerm}
              dateRange={{
                from: searchData.startDate,
                to: searchData.endDate,
              }}
              rollupStatusCode={searchData.rollupStatusCode}
              orderBy={searchData.orderBy}
            />
            <ClaimsList claims={claims} isLoading={isLoading} />
          </ContentWrapper>
          <div className="col-span-3">
            <Outlet context={context} />
          </div>
        </div>
      </ContentWrapper>
    </Page>
  );
}
