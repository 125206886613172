import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { cn } from '~/utils/shadcn';
import { buttonVariants } from './Button';

function IconLeft() {
  return <ChevronLeftIcon className="h-4 w-4" />;
}

function IconRight() {
  return <ChevronRightIcon className="h-4 w-4" />;
}

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-neutral-500 rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'h-9 w-9 flex items-center justify-center text-sm p-0 relative [&:has([aria-selected].day-outside)]:bg-neutral-100/50 [&:has([aria-selected])]:bg-neutral-100 [&:has([aria-selected].day-range-start)]:rounded-l-md [&:has([aria-selected].day-range-end)]:rounded-r-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
          'aria-selected:[&:where(.day-range-start,.day-range-end)]:bg-neutral-900 aria-selected:[&:where(.day-range-start,.day-range-end)]:text-neutral-50 hover:aria-selected:[&:where(.day-range-start,.day-range-end)]:bg-neutral-900 hover:aria-selected:[&:where(.day-range-start,.day-range-end)]:text-neutral-50 focus:aria-selected:[&:where(.day-range-start,.day-range-end)]:bg-neutral-900 focus:aria-selected:[&:where(.day-range-start,.day-range-end)]:text-neutral-50',
        ),
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_today:
          '[&:not(aria-selected)]:bg-neutral-100 [&:not(aria-selected)]:text-neutral-900',
        day_outside:
          'day-outside text-neutral-500 opacity-50 aria-selected:bg-neutral-100/50 aria-selected:text-neutral-500 aria-selected:opacity-30',
        day_disabled: 'text-neutral-500 opacity-50',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft,
        IconRight,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
