import {
  CrewClaimRollupStatusCode,
  crewClaimRollupStatusCodeName,
  CrewMerchantUi,
  ReportColumn,
  SearchableCrewClaimResolutionMethod,
} from 'corso-types';

import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { PropsWithChildren, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from '~/api';
import Alert from '~/components/Alert';
import Button from '~/components/Button';
import Card from '~/components/Card';
import DescriptionList, { Description } from '~/components/DescriptionList';
import { TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import InfoPopover from '~/components/InfoPopover';
import Modal from '~/components/Modal';
import { MultiSelect } from '~/components/ui/MultiSelect';
import {
  DatePickerWithRange,
  getDefinedDateRange,
} from '~/components/ui/primitives/DateRangeWithPicker';
import { Label } from '~/components/ui/primitives/Label';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useCustomFields } from '~/hooks/useCustomFields';
import useNavigateSearch from '~/hooks/useNavigateSearch';
import { useStoreId } from '~/hooks/useStoreId';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';

const searchableResolutionMethodName = {
  [SearchableCrewClaimResolutionMethod.refund]: 'Refund',
  [SearchableCrewClaimResolutionMethod.variantExchange]: 'Exchange',
  [SearchableCrewClaimResolutionMethod.giftCard]: 'Gift Card',
  [SearchableCrewClaimResolutionMethod.warrantyReview]: 'Warranty Review',
} as const satisfies Record<SearchableCrewClaimResolutionMethod, string>;

export type ClaimSearchProps = PropsWithChildren<{
  searchTerm: string;
  dateRange: {
    from: Date;
    to: Date;
  };
  rollupStatusCode: CrewClaimRollupStatusCode | 'all';
  requestedResolutionMethod: SearchableCrewClaimResolutionMethod | 'all';
  orderBy: 'asc' | 'desc';
}>;

function RunReport({
  show,
  claimType,
  startDate,
  endDate,
  resolutionMethod,
  statusCode,
  orderBy,
  searchTerm,
  onClose,
}: {
  claimType: 'Return' | 'Warranty';
  orderBy: 'asc' | 'desc';
  startDate: string;
  endDate: string;
  statusCode: CrewClaimRollupStatusCode | 'all';
  resolutionMethod: SearchableCrewClaimResolutionMethod | 'all';
  show: boolean;
  searchTerm: string;
  onClose: () => void;
}) {
  const storeId = useStoreId();
  const { user } = useMerchantContext();

  const { data: customFields = [] } = useCustomFields();

  const defaultColumns = Object.values(ReportColumn);

  const customFieldSelectOptions = customFields.map((customField) => ({
    label: customField.displayName,
    value: customField.id,
  }));

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm<CrewMerchantUi.ReportClaimReqFormValues>({
    values: {
      columns: defaultColumns,
      inspectionCustomFieldIds: [],
      lineItemCustomFieldIds: [],
    },

    resolver: zodResolver(CrewMerchantUi.reportRequestFormValueSchema),
  });

  const { mutate: runReport, isPending } = useMutation({
    mutationFn: api.store(storeId).report,
  });

  const closeAndReset = () => {
    onClose();
    reset();
  };

  const columnOptions = Object.values(ReportColumn).map((value) => ({
    label: value,
    value,
  }));

  const descriptions: Description[] = [];

  descriptions.push({
    term: 'Start Date',
    details: formatter.date(startDate),
  });

  descriptions.push({
    term: 'End Date',
    details: formatter.date(endDate),
  });

  descriptions.push({
    term: 'Status',
    details:
      statusCode === 'all' ? 'All' : crewClaimRollupStatusCodeName[statusCode],
  });

  descriptions.push({
    term: 'Resolution',
    details:
      resolutionMethod === 'all' ? 'All' : (
        searchableResolutionMethodName[resolutionMethod]
      ),
  });

  if (searchTerm.length) {
    descriptions.push({
      term: 'Search Term',
      details: searchTerm,
    });
  }

  const formId = 'generate-report-form';
  return (
    <Modal
      title="Export Claims"
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={closeAndReset}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
            loading={isPending}
          >
            Export
          </Button>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((body) => {
          onClose();
          runReport({
            email: user.email,
            filter: {
              searchTerm,
              kind: claimType,
              startDate,
              endDate,
              orderBy,
              statusCodes: statusCode === 'all' ? undefined : [statusCode],
              requestedResolutionMethods:
                resolutionMethod === 'all' ? undefined : [resolutionMethod],
            },
            ...body,
          });
        })}
        className="flex flex-col gap-4"
      >
        <Alert
          message={
            <p>
              Your export will be emailed to:{' '}
              <span className="font-bold">{user.email}.</span>
            </p>
          }
        />
        <span className="text-sm font-medium"> Filter Details</span>
        <DescriptionList descriptions={descriptions} />

        <Controller
          name="columns"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiSelect
              togglable
              label="Columns"
              options={columnOptions}
              value={value.map((option) => ({ label: option, value: option }))}
              placeholder="Select columns"
              onChange={(selected) => onChange(selected.map((v) => v.label))}
              error={errors.columns?.message}
            />
          )}
        />

        <Controller
          name="lineItemCustomFieldIds"
          control={control}
          render={({ field, fieldState }) => (
            <MultiSelect
              togglable
              details="Custom Field responses collected in the Customer Portal."
              label="Custom Field Responses - Customer Provided"
              options={customFieldSelectOptions.map(({ label, value }) => ({
                label,
                value: `${value}`,
              }))}
              value={field.value.map((value) => ({
                label: customFieldSelectOptions.find((cf) => cf.value === value)
                  ?.label,
                value: `${value}`,
              }))}
              onChange={(selections) =>
                field.onChange(
                  selections.map(({ value }) => Number.parseInt(value, 10)),
                )
              }
              placeholder="Select custom fields"
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          name="inspectionCustomFieldIds"
          control={control}
          render={({ field, fieldState }) => (
            <MultiSelect
              togglable
              label="Custom Field Responses - Inspection"
              placeholder="Select custom fields"
              options={customFieldSelectOptions.map(({ label, value }) => ({
                label,
                value: `${value}`,
              }))}
              value={field.value.map((value) => ({
                label: customFieldSelectOptions.find((cf) => cf.value === value)
                  ?.label,
                value: `${value}`,
              }))}
              onChange={(selections) =>
                field.onChange(
                  selections.map(({ value }) => Number.parseInt(value, 10)),
                )
              }
              error={fieldState.error?.message}
            />
          )}
        />
      </form>
    </Modal>
  );
}

function SharedPopover({ content }: { content: string }) {
  return (
    <InfoPopover title="Search Details">
      <InfoPopover.Prose>{content}</InfoPopover.Prose>
    </InfoPopover>
  );
}

/** Search updates the navigable URL, which should trigger updates in state. */
export default function ClaimSearch({
  searchTerm,
  dateRange,
  rollupStatusCode,
  requestedResolutionMethod,
  orderBy,
  children,
}: ClaimSearchProps) {
  const navigateSearch = useNavigateSearch();

  const [showReport, setShowReport] = useState(false);

  return (
    <Card>
      <RunReport
        searchTerm={searchTerm}
        orderBy={orderBy}
        show={showReport}
        claimType="Return"
        startDate={dateRange.from.toISOString()}
        endDate={dateRange.to.toISOString()}
        statusCode={rollupStatusCode}
        resolutionMethod={requestedResolutionMethod}
        onClose={() => setShowReport(false)}
      />
      <div className="flex flex-col gap-2">
        <Label className="flex items-center gap-0.5">
          Search
          <SharedPopover
            content="Search by email, claim number, claim tag, order number, customer
          name, or return tracking number."
          />
        </Label>

        <TextInput
          id="search"
          label="Search"
          className="flex-grow"
          placeholder="Search claims"
          onChange={(e) => {
            navigateSearch(new URLSearchParams({ searchTerm: e.target.value }));
          }}
          addon={{
            outsideEnd: (
              <IconAction.Button
                icon={DocumentArrowDownIcon}
                iconSize="md"
                title="Edit Customer"
                onClick={() => setShowReport(true)}
              />
            ),
          }}
          value={searchTerm}
          labelVisuallyHidden
        />
      </div>

      <SimpleSelect
        label="Status"
        value={rollupStatusCode}
        options={[
          { value: 'all', label: 'All' } as const,
          ...Object.values(CrewClaimRollupStatusCode).map((value) => ({
            value,
            label: crewClaimRollupStatusCodeName[value],
          })),
        ]}
        onChange={(selectedRollupStatusCode) => {
          navigateSearch(
            new URLSearchParams({
              rollupStatusCode: selectedRollupStatusCode,
            }),
          );
        }}
      />

      <SimpleSelect
        label={
          <div className="flex items-center gap-0.5">
            Resolution
            <SharedPopover content="Filter by customer requested resolution method." />
          </div>
        }
        value={requestedResolutionMethod}
        options={[
          { value: 'all', label: 'All' } as const,
          ...Object.values(SearchableCrewClaimResolutionMethod).map(
            (value) => ({
              value,
              label: searchableResolutionMethodName[value],
            }),
          ),
        ]}
        onChange={(method) => {
          navigateSearch(
            new URLSearchParams({
              requestedResolutionMethod: method,
            }),
          );
        }}
      />

      <div className="flex flex-col gap-2">
        <Label>Created Date</Label>
        <DatePickerWithRange
          value={dateRange}
          onChange={(selectedDateRange) => {
            const definedDateRange = getDefinedDateRange(selectedDateRange);
            navigateSearch(
              new URLSearchParams({
                startDate: definedDateRange.from.toISOString(),
                endDate: definedDateRange.to.toISOString(),
              }),
            );
          }}
        />
      </div>

      <SimpleSelect
        label="Order By"
        value={orderBy}
        options={[
          { value: 'asc', label: 'Oldest First' },
          { value: 'desc', label: 'Newest First' },
        ]}
        onChange={(selectedOrderBy) => {
          navigateSearch(
            new URLSearchParams({
              orderBy: selectedOrderBy,
            }),
          );
        }}
      />
      {children}
    </Card>
  );
}
