import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import {
  ArrowTopRightOnSquareIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/20/solid';

import { EnvelopeIcon, HomeIcon, UserIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { ClaimResolutionMethodEnum, CrewMerchantUi, toEnum } from 'corso-types';
import { formatRelative } from 'date-fns';
import { PropsWithChildren, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import api from '~/api';
import Address from '~/components/Address';
import Alert from '~/components/Alert';
import Button from '~/components/Button';
import Card from '~/components/Card';
import EmptyDetails from '~/components/claim/EmptyDetails';
import ExchangeOrderLineItem from '~/components/claim/resolutionLineItems/ExchangeOrderLineItem';
import RefundLineItem from '~/components/claim/resolutionLineItems/RefundLineItem';
import DescriptionList from '~/components/DescriptionList';
import DetailInfoItem from '~/components/DetailInfoItem';
import Feed from '~/components/Feed';
import IconAction from '~/components/IconAction';
import LineItem from '~/components/LineItem';
import MediaGallery from '~/components/MediaGallery';
import Menu from '~/components/Menu';
import Modal from '~/components/Modal';
import RelativeDateTime from '~/components/RelativeDateTime';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import { useStoreId } from '~/hooks/useStoreId';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  shippingClaimReasonName,
  shippingClaimResolutionMethodName,
  shippingClaimSourceName,
  shippingClaimStatusName,
} from '~/utils/enumNameMaps';
import { formatter } from '~/utils/formatter';

// todo: consolidate the feed components
// todo: fix the mobile UI view
// todo: add error handling on the mutations and queries

function FinalizeShippingClaim({
  resolutionMethod,
}: {
  resolutionMethod: ClaimResolutionMethodEnum;
}) {
  const { shippingClaimId } = useParams();
  const storeId = useStoreId();

  const { formState, control, getValues } = useForm({
    resolver: zodResolver(
      z.object({
        resolutionMethod: z.nativeEnum(ClaimResolutionMethodEnum),
        // ? include shippingClaimId, storeId
      }),
    ),
    defaultValues: {
      resolutionMethod,
    },
  });

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const queryClient = useQueryClient();

  const { mutate: finalizeClaim, isPending } = useMutation({
    mutationKey: ['shippingClaimFinalize', { shippingClaimId, storeId }],
    mutationFn: (selectedResolutionMethod: ClaimResolutionMethodEnum) => {
      if (!shippingClaimId) throw new Error('Missing Claim ID');
      return api
        .store(storeId)
        .shippingProtection.claim(shippingClaimId)
        .finalize(selectedResolutionMethod);
    },
    onSettled: () => {
      closeModal();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['shippingClaim', { shippingClaimId, storeId }],
        data,
      );
      // * invalidate the claims query to update the claims list
      return queryClient.invalidateQueries({ queryKey: ['shippingClaims'] });
    },
  });

  const onSubmit = () => finalizeClaim(getValues('resolutionMethod'));

  return (
    <>
      <Button
        variant="success"
        className="w-full sm:w-auto"
        loading={formState.isSubmitting || isPending}
        onClick={() => setShowModal(true)}
      >
        Finalize Claim
        {/* // ? might want an info icon with popover to better clarify what "finalize" means; i.e. submitting all selections across all line items */}
      </Button>
      <Modal
        show={showModal}
        title="Finalize Claim"
        onClose={closeModal}
        actions={
          <>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              variant="success"
              onClick={onSubmit}
              loading={formState.isSubmitting || isPending}
            >
              Finalize Claim
              {/* // ? possibly adjust button to morph on field state */}
            </Button>
          </>
        }
      >
        <form className="flex flex-col gap-4">
          <Controller
            control={control}
            name="resolutionMethod"
            render={({ field, fieldState }) => (
              <SimpleSelect
                label="Resolution Method"
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
                options={Object.values(ClaimResolutionMethodEnum).map(
                  (value) => ({
                    value,
                    label: shippingClaimResolutionMethodName[value],
                  }),
                )}
              />
            )}
          />
        </form>
      </Modal>
    </>
  );
}

function CorsoIcon() {
  // a real version of this SVG should be exported through Adobe Illustrator to optimize the `viewBox`
  return (
    <svg
      className="h-full"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      fill="currentColor"
      viewBox="0 0 200 234.59"
    >
      <g>
        <path d="M0,117.3c0,58.36,42.95,106.67,98.96,115.09v-62.37c-22.13-7.31-38.12-28.13-38.12-52.72s15.99-45.4,38.12-52.72V2.21 C42.95,10.62,0,58.94,0,117.3z" />
        {/* maybe could be simplified as a `circle` */}
        <path d="M161.04,76.44c17.83,0,32.29-14.46,32.29-32.29c0-17.83-14.46-32.29-32.29-32.29s-32.29,14.46-32.29,32.29 C128.75,61.98,143.2,76.44,161.04,76.44z" />
        <circle cx="161.04" cy="190.45" r="32.29" />
      </g>
    </svg>
  );
}

function ShippingClaimGeneralInfo({
  claim,
}: PropsWithChildren<{
  claim: CrewMerchantUi.ShippingClaim;
}>) {
  const {
    status,
    createdOn,
    linkToCustomerUi,
    id,
    resolutionMethod,
    resolvedOn,
    resolvedIn,
    originalStoreOrder: { orderNo, platformAdminUrl },
  } = claim;

  const { data: spSettings } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const { storeUser } = useMerchantContext();

  const canFinalize = !!(
    spSettings?.isConfigured &&
    spSettings.isMerchantFinalizationEnabled &&
    !resolvedOn
  );

  return (
    <div className="grid grid-cols-1 gap-2 py-4 sm:gap-4 md:grid-cols-3">
      <div className="col-span-1 flex flex-col items-start justify-center gap-2 md:col-span-3">
        {linkToCustomerUi ?
          <a
            className="flex items-center gap-1 text-lg font-semibold hover:underline focus:underline"
            href={linkToCustomerUi}
            target="_blank"
            rel="noreferrer"
          >
            #{id}
            <ArrowTopRightOnSquareIcon className="h-4 w-4" />
          </a>
        : <p className="text-lg font-semibold">#{id}</p>}

        <span className="text-sm">
          <span>{shippingClaimStatusName[status]}</span>
        </span>
        <span className="text-xs text-corso-gray-500">
          Created <RelativeDateTime dateTime={createdOn} />
        </span>
        {resolvedOn && resolvedIn && (
          <span className="text-xs text-corso-gray-500">
            Finalized
            <RelativeDateTime dateTime={resolvedOn} /> by{' '}
            {resolvedIn === 'Merchant_App' ?
              `${storeUser.store.name} Team`
            : 'Corso Team'}
          </span>
        )}
      </div>
      <div className="flex items-start justify-end gap-2 sm:col-start-4 sm:row-start-1">
        {/* // TODO actual enum value comparison */}
        {canFinalize && (
          <FinalizeShippingClaim
            key={id} // ! pseudo-hack so the state of this component is reset when the claim changes
            resolutionMethod={toEnum(
              resolutionMethod,
              ClaimResolutionMethodEnum,
            )}
          />
        )}

        {/* only exposing a single menu item for now */}
        {platformAdminUrl && (
          <Menu
            align="end"
            buttonAs={
              <IconAction.Button
                icon={EllipsisVerticalIcon}
                title="More Actions"
                className="bg-white text-corso-gray-800"
              />
            }
          >
            <Menu.ItemNavLink to={platformAdminUrl} target="_blank">
              <ArrowTopRightOnSquareIcon className="h-5 w-5" />{' '}
              {orderNo ? `View Order ${orderNo}` : 'View Order'}
            </Menu.ItemNavLink>
          </Menu>
        )}
      </div>
    </div>
  );
}

function ShippingClaimRequest({
  claim,
}: {
  claim: CrewMerchantUi.ShippingClaim;
}) {
  const {
    resolutionMethod,
    shippingClaimLineItems,
    noteFromCustomer,
    reason,
    source,
    images,
    originalStoreOrder: { orderNo },
  } = claim;

  const descriptions = [
    {
      term: 'Resolution Method',
      details: shippingClaimResolutionMethodName[resolutionMethod],
    },
    {
      term: 'Reason',
      details: shippingClaimReasonName[reason],
    },
    {
      term: 'Source',
      details: source ? shippingClaimSourceName[source] : 'Customer Portal',
    },
    ...(noteFromCustomer ? [{ term: 'Note', details: noteFromCustomer }] : []),
  ];

  return (
    <Card>
      <Card.Title>
        {resolutionMethod} Requested By Customer For Order {orderNo}
      </Card.Title>

      <div className="divide-y rounded-md border p-4 shadow-sm [&>*]:py-4 first:[&>*]:pt-0 last:[&>*]:pb-0">
        <DescriptionList size="xs" descriptions={descriptions} />
        {shippingClaimLineItems.map((li) => (
          <LineItem
            variant="small"
            key={li.id}
            imageUrl={li.originalStoreOrderLineItem.imgUrl}
            sku={li.originalStoreOrderLineItem.sku}
            name={li.originalStoreOrderLineItem.name}
            quantity={li.quantity}
            options={li.originalStoreOrderLineItem.optionsFromPlatform}
          />
        ))}
        {images && images.length > 0 && (
          <section className="flex flex-col gap-2">
            <span className="text-xs font-medium text-corso-gray-500 lg:pb-4">
              Customer Uploaded Media
            </span>
            <MediaGallery
              media={images.map((url) => ({
                src: url,
                alt: 'Customer Uploaded Media',
              }))}
            />
          </section>
        )}
      </div>
    </Card>
  );
}

function ShippingClaimCustomerDetail({
  claim,
}: {
  claim: CrewMerchantUi.ShippingClaim;
}) {
  const { customerEmail, customerName, shippingAddress } = claim;

  return (
    <Card>
      <Card.Title>Customer</Card.Title>
      {/* customer name */}
      <DetailInfoItem
        icon={<UserIcon className="h-4 w-4" />}
        content={customerName}
      />

      {/* customer address */}
      {shippingAddress && (
        <DetailInfoItem
          icon={<HomeIcon className="h-4 w-4" />}
          className="items-start"
          content={<Address address={shippingAddress} />}
        />
      )}

      {/* customer email */}
      <DetailInfoItem
        icon={<EnvelopeIcon className="h-4 w-4" />}
        content={customerEmail}
      />
    </Card>
  );
}

function ShippingClaimAlert({
  showCorsoManagedAlert,
  someFinalized,
}: {
  showCorsoManagedAlert: boolean;
  someFinalized: boolean;
}) {
  // if the claim is being handled by the Corso Concierge Team, always show the alert
  if (showCorsoManagedAlert) {
    return (
      <Alert
        variant="DEFAULT"
        message={`This claim ${
          someFinalized ? 'was' : 'is currently being'
        } handled by the Corso Concierge Team.`}
      />
    );
  }

  // if the claim is handled by the merchant and has not been finalized, show the alert
  if (!someFinalized) {
    return (
      <Alert
        variant="DEFAULT"
        message="This claim has not been finalized yet."
      />
    );
  }

  return null;
}

function ShippingClaimResolutionSummary({
  claim,
}: {
  claim: CrewMerchantUi.ShippingClaim;
}) {
  const { data: spSettings } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const { resolutionSummary } = claim;

  const showCorsoManagedAlert =
    !!spSettings?.isConfigured && !spSettings.isMerchantFinalizationEnabled;

  const someFinalized = !!(
    resolutionSummary?.exchangeOrder ?? resolutionSummary?.refund
  );

  return (
    <Card>
      <Card.Title>Resolution Summary</Card.Title>

      <ShippingClaimAlert
        showCorsoManagedAlert={showCorsoManagedAlert}
        someFinalized={someFinalized}
      />

      {resolutionSummary?.exchangeOrder && (
        <div className="flex flex-col gap-4 divide-y rounded-md border p-4 shadow-sm">
          <ExchangeOrderLineItem
            lineItems={resolutionSummary.exchangeOrder.lineItems.map(
              (lineItem) => ({
                key: lineItem.id,
                name: lineItem.name,
                imageUrl: lineItem.imgUrl,
                price: lineItem.unitPrice,
                quantity: lineItem.quantity,
                sku: lineItem.sku,
                options: lineItem.optionsFromPlatform,
              }),
            )}
            replacementOrder={{
              adminLink: resolutionSummary.exchangeOrder.platformAdminUrl,
              nameFromPlatform:
                resolutionSummary.exchangeOrder.orderNameFromPlatform,
            }}
          />
        </div>
      )}

      {resolutionSummary?.refund && (
        <div className="flex flex-col gap-4 rounded-md border p-4 shadow-sm">
          <RefundLineItem amount={resolutionSummary.refund.amount} />
        </div>
      )}
    </Card>
  );
}

function ContentIFrame({ title, srcDoc }: { title: string; srcDoc: string }) {
  const [height, setHeight] = useState<number>();
  return (
    <iframe
      style={{
        '--iframe-height': height ? `${height}px` : 'auto',
      }}
      onLoad={(e) =>
        setHeight(e.currentTarget.contentDocument?.body.scrollHeight)
      }
      // height + 1% to try and avoid scrolling if possible
      className="h-[calc(var(--iframe-height)*1.01)] min-h-[--iframe-height] w-full"
      title={title}
      srcDoc={srcDoc}
    />
  );
}

function ShippingClaimMessages({
  claim: shippingClaim,
}: {
  claim: CrewMerchantUi.ShippingClaim;
}) {
  const { emailConversation: data } = shippingClaim;
  if (!data) return null;

  const messages = data.messages.map((message) => ({
    id: message.createdOn,
    iconSize: '32px',
    kind: 'message' as const,
    from: message.from,
    createdOn: message.createdOn,
    messageBody: message.sanitizedHtmlBody,
  }));

  return (
    <Card>
      <Card.Title>Concierge Messages</Card.Title>
      <div className="space-y-2 rounded-md border border-corso-gray-200 p-3 shadow-sm lg:p-4">
        <div className="space-y-4">
          <dl className="grid grid-cols-[auto_1fr] gap-2 text-xs">
            <dt className="font-semibold">Subject</dt>
            <dd className="break-words">{data.subject}</dd>
            <dt className="font-semibold">Customer</dt>
            <dd className="break-words">
              {data.customerName ?
                <>
                  {data.customerName}{' '}
                  <a
                    href={`mailto:${data.customerEmail}`}
                    className="text-right text-gray-500 hover:underline"
                  >
                    {data.customerEmail}
                  </a>
                </>
              : <a
                  href={`mailto:${data.customerEmail}`}
                  className="text-right text-gray-500 hover:underline"
                >
                  {data.customerEmail}
                </a>
              }
            </dd>
          </dl>
          {/* // ? maybe add icon distinguishing `from` */}
          <Feed
            events={messages}
            renderIcon={(message) => {
              if (message.from === data.customerEmail)
                return (
                  <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-white font-bold text-gray-500">
                    {(data.customerName ?? data.customerEmail).charAt(0)}
                  </div>
                );
              return (
                <div className="flex h-8 w-8 items-center justify-center rounded-full border bg-white p-2 text-gray-500">
                  <CorsoIcon />
                </div>
              );
            }}
          >
            {(message) => {
              const customerNameOrEmail =
                message.from === data.customerEmail && data.customerName ?
                  data.customerName
                : message.from;

              const title = `Message from ${customerNameOrEmail} on ${formatter.date(
                message.createdOn,
              )}`;

              return (
                <>
                  <div className="flex justify-between rounded-t-md border p-2 text-xs">
                    <span className="font-bold">{customerNameOrEmail}</span>
                    <time
                      dateTime={message.createdOn}
                      className="text-gray-500"
                    >
                      {formatRelative(new Date(message.createdOn), new Date())}
                    </time>
                  </div>
                  <div className="rounded-b-md border-x border-b">
                    <ContentIFrame
                      title={title}
                      srcDoc={`<!DOCTYPE html><html><head><title>${title}</title><style>body{background:white;padding:1rem;font-family:Arial;font-size:100%;}</style></head><body>${message.messageBody}</body></html>`}
                    />
                  </div>
                </>
              );
            }}
          </Feed>
        </div>
      </div>
    </Card>
  );
}

/** Orchestrates all the claim components together for rendering within the context. */
export default function ShippingClaimOverview() {
  const { shippingClaimId } = useParams();
  const storeId = useStoreId();

  const {
    data: shippingClaim,
    isLoading,
    isError,
    // refetch,
  } = useQuery({
    enabled: !!shippingClaimId,
    queryKey: ['shippingClaim', { shippingClaimId, storeId }],
    queryFn: () => {
      if (!shippingClaimId) throw new Error('Missing Claim ID');
      return api.store(storeId).shippingProtection.claim(shippingClaimId).get();
    },
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (!shippingClaim || isLoading)
    return (
      <EmptyDetails
        claimType="Shipping"
        title="Loading Shipping Claim"
        description="Please wait while we load the claim."
      />
    );

  if (isError)
    return (
      <EmptyDetails
        claimType="Shipping"
        title="Error Loading Shipping Claim"
        description="Please try again later."
      />
    );

  return (
    <>
      <ShippingClaimGeneralInfo claim={shippingClaim} />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-[3fr_1fr]">
        <div className="space-y-4">
          <ShippingClaimResolutionSummary claim={shippingClaim} />
          <ShippingClaimRequest claim={shippingClaim} />
          <ShippingClaimMessages claim={shippingClaim} />
        </div>
        <div className="order-first space-y-4 md:order-none">
          <ShippingClaimCustomerDetail claim={shippingClaim} />
        </div>
      </div>
    </>
  );
}
