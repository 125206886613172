import { CalendarIcon } from '@heroicons/react/24/outline';
import { subDays } from 'date-fns';
import { DateRange } from 'react-day-picker';

import { cn } from '~/utils/shadcn';
import { Button } from './Button';
import { Calendar } from './Calendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';

/**
 * Using a `dateRange` gets a usable and well-defined known date range.
 * If the `dateRange` is `undefined` it will fallback to the `defaultRange`, which will default to the last 30 days if also `undefined`.
 */
export function getDefinedDateRange(
  dateRange?: DateRange,
  defaultRange?: { from: Date; to: Date },
) {
  const { from, to } = dateRange ?? {};
  if (from && to) return { from, to };
  if (from) return { from, to: from }; // ? may not be useful as this is likely one point in time
  return defaultRange ?? { from: subDays(new Date(), 30), to: new Date() };
}

type DatePickerWithRangeProps = {
  className?: string;
  onChange?: (range: DateRange | undefined) => void;
  value?: DateRange | undefined;
};

// very close the the shadcn/ui component: https://ui.shadcn.com/docs/components/date-picker#date-range-picker
export function DatePickerWithRange({
  className,
  onChange,
  value: date,
}: DatePickerWithRangeProps) {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className="justify-start font-normal">
            <CalendarIcon className="mr-2 h-4 w-4" />

            {/* eslint-disable-next-line no-nested-ternary -- used just for formatting the output */}
            {date?.from ?
              date.to ?
                <>
                  {/* // TODO consider alternative formats; i.e. 'LLL dd, y' */}
                  {date.from.toLocaleDateString()} -{' '}
                  {date.to.toLocaleDateString()}
                </>
              : date.from.toLocaleDateString()
            : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={onChange}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
