import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';
import ResolutionLineItemTitle from './ReslutionLineItemTitle';
import { MonetaryResolutionLineItemProps } from './types';

export default function GiftCardLineItem({
  compareAmount,
  amount,
  merchandiseAmount,
  incentiveAmount,
  replacementOrder,
}: MonetaryResolutionLineItemProps & {
  merchandiseAmount?: number;
  incentiveAmount?: number;
  // * replacement order is not always present on gift-cards
  // * ex: shopify plus customers will be able to issue gift-cards without a replacement order
  replacementOrder?: {
    nameFromPlatform: string | undefined;
    adminLink: string | undefined;
  };
}) {
  const {
    storeUser: {
      store: { currencyCode },
    },
  } = useMerchantContext();

  return (
    <ResolutionLineItemTitle
      title="Gift Card"
      orderNumber={replacementOrder?.nameFromPlatform}
      orderHref={replacementOrder?.adminLink}
    >
      <div>
        <p className="flex justify-between gap-2 text-xs text-corso-gray-500">
          <span>Amount</span>
          <div className="flex gap-1">
            {compareAmount !== undefined && compareAmount !== amount && (
              <span className="text-corso-gray-500 line-through">
                {formatter.currency(compareAmount, currencyCode)}
              </span>
            )}
            <span>{formatter.currency(amount, currencyCode)}</span>
          </div>
        </p>

        {/* showing this value is only necessary if the incentive amount is greater than 0 */}
        {merchandiseAmount !== undefined &&
          incentiveAmount !== undefined &&
          incentiveAmount > 0 && (
            <p className="flex justify-between gap-2 text-xs text-corso-gray-500">
              <span>Merchandise Amount</span>
              <span>{formatter.currency(merchandiseAmount, currencyCode)}</span>
            </p>
          )}
        {/* show this value if the incentive amount is greater than 0 */}
        {incentiveAmount !== undefined && incentiveAmount > 0 && (
          <p className="flex justify-between gap-2 text-xs text-corso-gray-500">
            <span>Bonus Incentive Offered</span>
            <span>{formatter.currency(incentiveAmount, currencyCode)}</span>
          </p>
        )}
      </div>
    </ResolutionLineItemTitle>
  );
}
