import { ArrowTopRightOnSquareIcon, PlusIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { Pegasus } from 'corso-types';
import { FormEventHandler } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import Alert from '~/components/Alert';
import { LinkButton } from '~/components/Button';
import Card from '~/components/Card';
import ClipboardButton from '~/components/ClipboardButton';
import { SwitchInput, TextInput, UrlInput } from '~/components/field';
import FloatingSave from '~/components/FloatingSave';
import IconAction from '~/components/IconAction';
import { MultiSelect } from '~/components/ui/MultiSelect';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
} from '~/hooks/useConfigSettings';
import { useCustomFields } from '~/hooks/useCustomFields';
import { useStoreRules } from '~/hooks/useStoreRules';
import {
  useThemeSettings,
  useThemeSettingsUpdate,
} from '~/hooks/useThemeSettings';
import { RegistrationFormValues, RegistrationsFormSchema } from '~/types';
import { StoreRuleDisplay } from './storeRules/StoreRuleDisplay';

function RegistrationProductSettings() {
  const { data: storeRules } = useStoreRules();

  const productRegistrationAutomationRules =
    storeRules?.filter(
      (sr) => sr.rule.event.type === 'productRegistrationSelection',
    ) ?? [];

  const createAutomationLink = `../automations/create?${new URLSearchParams({
    eventType: Pegasus.EventType.productRegistrationSelection,
    returnTo: '../../registrations',
  }).toString()}`;

  return (
    <Card>
      <Card.Title as="h2">Registration Product Automations</Card.Title>
      {!productRegistrationAutomationRules.length && (
        <Alert
          variant="warning"
          message="Before registrations can be created, you must create an automation rule to determine which products are eligible for registration."
        />
      )}

      <ul className="flex flex-col gap-2">
        {productRegistrationAutomationRules.map((storeRule) => (
          <li key={storeRule.id}>
            <StoreRuleDisplay
              storeRule={storeRule}
              actions={
                <div className="flex items-center gap-2">
                  <SwitchInput
                    disabled
                    labelVisuallyHidden
                    checked={storeRule.isEnabled}
                    id={`isEnabled-${storeRule.id}`}
                    label={`${storeRule.name} isEnabled`}
                  />
                  <IconAction.Link
                    title={`Edit ${storeRule.name} Rule`}
                    icon={PencilIcon}
                    to={`../automations/edit/${storeRule.id}?${new URLSearchParams(
                      {
                        returnTo: '../../registrations',
                      },
                    ).toString()}`}
                  />
                </div>
              }
            />
          </li>
        ))}
      </ul>

      <div className="justify-s flex">
        <LinkButton
          variant="primary"
          title="Add New Product Automation"
          to={createAutomationLink}
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
          Add Product Automation
        </LinkButton>
      </div>
    </Card>
  );
}

function RegistrationTextCustomization() {
  const {
    register,
    formState: { errors },
  } = useFormContext<RegistrationFormValues>();

  return (
    <Card>
      <Card.Title as="h2">Registration Text Customization</Card.Title>
      <TextInput
        id="order-lookup-registration-text"
        label="Order Lookup Start Registration Text"
        details="This text will be displayed on the order lookup page to direct customers to the registration page."
        placeholder="Didn't purchase from our online store?"
        {...register('theme.orderLookupRegistrationText')}
        error={errors?.theme?.orderLookupRegistrationText?.message}
      />
      <TextInput
        id="identify-product-url"
        label="Product Identification URL"
        details="This URL will be used to direct customers to a page on your site where you can provide additional information to help them identify their product."
        placeholder="https://corso.com/locate-your-item-code"
        {...register('theme.identifyProductUrl')}
        error={errors?.theme?.identifyProductUrl?.message}
      />
      <TextInput
        id="identify-product-url-button-text"
        label="Product Identification Button Text"
        details="This text will be displayed on the button that directs customers to the identify product page."
        placeholder="Find Your Product Here"
        {...register('theme.identifyProductUrlButtonText')}
        error={errors?.theme?.identifyProductUrlButtonText?.message}
      />
      <TextInput
        id="identify-product-detail-text"
        label="Product Identification Detail Text"
        details="This text will be displayed alongside the identify product button, it is typically used to provide additional information or instructions to the customer."
        placeholder="Please search for the product, based on it's name, SKU or barcode number. if you need help please visit our site."
        {...register('theme.identifyProductDetailText')}
        error={errors?.theme?.identifyProductDetailText?.message}
      />
      <TextInput
        id="identify-product-form-placeholder"
        label="Product Identification Search Placeholder"
        details="This text will be displayed as a placeholder in the search field on the identify product page."
        placeholder="Search by product name"
        {...register('theme.identifyProductFormPlaceholder')}
        error={errors?.theme?.identifyProductFormPlaceholder?.message}
      />
    </Card>
  );
}

export default function RegistrationSettings() {
  const formId = 'registration-settings';
  const { data: registrationSettings } = useConfigSettings(
    ({ registrations }) => registrations,
  );
  const { data: themeSettings } = useThemeSettings();
  const { mutateAsync: saveRegistrationSettings } = useConfigSettingsUpdate();
  const { mutateAsync: saveThemeSettings } = useThemeSettingsUpdate();
  const registrationUrl =
    themeSettings?.customerAppUrl ?
      `${themeSettings.customerAppUrl}/register`
    : '';

  const { data: customFields = [] } = useCustomFields();
  const customFieldSelectOptions = customFields?.map((customField) => ({
    label: customField.displayName,
    value: customField.id,
  }));

  const formMethods = useForm<RegistrationFormValues>({
    resolver: zodResolver(RegistrationsFormSchema),
    defaultValues: {
      isRegistrationEnabled: false,
    },
    values:
      registrationSettings && themeSettings ?
        {
          ...registrationSettings,
          theme: {
            identifyProductUrl: themeSettings.identifyProductUrl ?? '',
            identifyProductUrlButtonText:
              themeSettings.identifyProductUrlButtonText ?? '',
            identifyProductDetailText:
              themeSettings.identifyProductDetailText ?? '',
            identifyProductFormPlaceholder:
              themeSettings.identifyProductFormPlaceholder ?? '',
            orderLookupRegistrationText:
              themeSettings.orderLookupRegistrationText ?? '',
          },
        }
      : undefined,
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
    watch,
  } = formMethods;

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit(({ theme: themeChanges, ...registrations }) =>
      Promise.all([
        saveRegistrationSettings({ registrations }),
        saveThemeSettings(themeChanges),
      ]).then(() => {
        formMethods.reset();
      }),
    )(event).catch(console.error);
  };

  const isRegistrationEnabled = watch('isRegistrationEnabled');
  const registrationChannels = registrationSettings?.salesChannels ?? [];

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...formMethods}>
        <form
          className="flex flex-col gap-5"
          id={formId}
          onSubmit={submitHandler}
          onReset={(e) => {
            e.preventDefault();
            reset();
          }}
        >
          <FloatingSave
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            form={formId}
          />
          <Card>
            <div
              className={twMerge(
                'flex items-center justify-between gap-2',
                isRegistrationEnabled && 'pb-2',
              )}
            >
              <Card.Title as="h2">Registrations</Card.Title>
              <Controller
                control={control}
                name="isRegistrationEnabled"
                render={({ field: { onChange, value }, fieldState }) => (
                  <SwitchInput
                    id="is-registration-enabled"
                    label="Registrations Offered"
                    checked={!!value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>
            {isRegistrationEnabled && (
              <>
                <Alert
                  variant="DEFAULT"
                  message={
                    <div>
                      Registrations allow customers to create warranty claims on
                      products purchased at a location other than your online
                      store.
                      <br />
                      <br />
                      Customers will be able to search for products based on
                      their name, SKU, or barcode number.
                    </div>
                  }
                />

                <UrlInput
                  id="registration-url"
                  label="Customer Portal Registration URL"
                  details="Use this link to start a registration, customers will also be able to access the registration page from the order lookup on your Customer Portal."
                  value={registrationUrl}
                  required
                  readOnly
                  disabled
                  addon={{
                    outsideEnd: (
                      // using `grid` on mobile and `flex` on desktop avoid apply `flex-grow` to each child
                      <div className="grid grid-cols-2 items-center gap-2 md:flex">
                        {/* // ? adjust to be styled as a button group  */}
                        <ClipboardButton onClick={() => registrationUrl} />
                        <IconAction.Link
                          to={registrationUrl}
                          target="_blank"
                          title="Visit"
                          icon={ArrowTopRightOnSquareIcon}
                        />
                      </div>
                    ),
                  }}
                />

                <Controller
                  control={control}
                  name="isRegistrationWarrantyClaimEnabled"
                  defaultValue={false}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <SwitchInput
                      id="allow-warranty-claims"
                      label="Offer Retail Warranty Claims"
                      details="Allow customers to create warranty claims from a registration."
                      checked={!!value}
                      onChange={onChange}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </>
            )}
          </Card>

          {isRegistrationEnabled && (
            <>
              <Card>
                <Card.Title>Data Collection</Card.Title>

                <Controller
                  control={control}
                  name="shouldCollectRegistrationAddress"
                  defaultValue={false}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <SwitchInput
                      id="should-collect-address"
                      label="Collect Address"
                      details="Collect the customer's address when creating a registration, (address will always be collected for warranty claims created from a registration)."
                      checked={!!value}
                      onChange={onChange}
                      error={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="shouldCollectRegistrationProofOfPurchase"
                  defaultValue={false}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <SwitchInput
                      id="require-proof-of-purchase"
                      label="Require Proof of Purchase"
                      details="Require customers to provide proof of purchase when creating a Registration."
                      checked={!!value}
                      onChange={onChange}
                      error={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  name="offeredSalesChannels"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultiSelect
                      label="Channels"
                      options={registrationChannels.map((channel) => ({
                        label: channel,
                        value: channel,
                      }))}
                      value={value.map((channel) => ({
                        label: channel,
                        value: channel,
                      }))}
                      placeholder="Add a Channel"
                      onChange={(selections) =>
                        onChange(selections.map((selection) => selection.value))
                      }
                      details="Channels represent the original purchase location of a product, these channels do not correspond to Shopify sales channels."
                      creatable
                    />
                  )}
                />

                <Controller
                  name="customFieldIds"
                  control={control}
                  render={({ field, fieldState }) => (
                    <MultiSelect
                      togglable
                      details="Custom fields are additional data points that can be collected when a customer creates a registration."
                      label="Custom Fields"
                      placeholder="Select custom fields"
                      options={customFieldSelectOptions.map(
                        ({ label, value }) => ({
                          label,
                          value: `${value}`,
                        }),
                      )}
                      value={field.value.map((value) => ({
                        label: customFieldSelectOptions.find(
                          (cf) => cf.value === value,
                        )?.label,
                        value: `${value}`,
                      }))}
                      onChange={(selections) =>
                        field.onChange(
                          selections.map(({ value }) =>
                            Number.parseInt(value, 10),
                          ),
                        )
                      }
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Card>
              <RegistrationProductSettings />
              <RegistrationTextCustomization />
            </>
          )}
        </form>
      </FormProvider>
    </>
  );
}
