import ClaimDetail from '~/components/claim/ClaimDetail';
import ClaimGeneralInfo from '~/components/claim/ClaimGeneralInfo';
import ClaimLineItemList from '~/components/claim/ClaimLineItemList';
import ClaimReviewActions from '~/components/claim/ClaimReviewActions';
import ClaimSummary from '~/components/claim/ClaimSummary';
import ClaimTimeline from '~/components/claim/ClaimTimeline';
import { RegistrationSummary } from '~/components/claim/RegistrationSummary';
import { ClaimReviewProvider } from '~/providers/ClaimReviewProvider';

// ! order should only be able to have a shipping claim following the same pattern in the customer UI.. IE... it has wasShippingProtected = true or isUnprotectedOrderClaimPermitted = true

/** Orchestrates all the claim components together for rendering within the context. */
export default function ClaimReview() {
  return (
    <ClaimReviewProvider>
      <ClaimGeneralInfo>
        <ClaimReviewActions />
      </ClaimGeneralInfo>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-[3fr_1fr]">
        <div className="space-y-4">
          <ClaimSummary />
          <ClaimLineItemList />
          <ClaimTimeline />
        </div>
        <div className="space-y-4">
          <RegistrationSummary />
          <ClaimDetail />
        </div>
      </div>
    </ClaimReviewProvider>
  );
}
