import {
  ArrowUturnLeftIcon,
  DocumentCheckIcon,
} from '@heroicons/react/24/outline';
import { useCallback, useRef } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Button from './Button';
import ConfirmModal from './ConfirmModal';
import { Transition } from './ui/primitives/Transition';

/**
 * A wrapper that shows floating actions to save or revert changes.
 * With unsaved changes shows a confirmation modal block, and sets up a handler for the `beforeunload` event.
 *
 * Using a `formId` referencing the `id` of `form`, the component will trigger `reset` and `submit` events to the form.
 * Otherwise, both `onReset` and `onSubmit` handlers are required to handle the actions.
 */
export default function FloatingSave({
  onReset,
  onSubmit,
  form,
  isDirty = false,
  isSubmitting = false,
}: { isSubmitting?: boolean; isDirty?: boolean } & (
  | { onSubmit: () => void; onReset: () => void; form?: never }
  | { onSubmit?: never; onReset?: never; form: string }
)) {
  const resetRef = useRef<HTMLButtonElement>(null);
  const show = isDirty || isSubmitting;

  // using `useBlocker` instead of `usePrompt` due to compatibility concerns
  // used to display custom prompt on SPA navigation
  const blocker = useBlocker(isDirty);

  // handles `beforeunload` event to display native browser prompt such as refresh
  const onBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!isDirty) return; // ? needs to also include submitting

      // cancel the event as stated by the standard
      event.preventDefault();
      /** set `returnValue` to help with compatibility @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes */
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
    [isDirty],
  );

  // could use `useEffect`, but this abstraction is already available and does the handler cleanup
  useBeforeUnload(onBeforeUnload);

  return (
    <>
      <Transition show={show}>
        <div
          className={twMerge(
            'fixed bottom-6 right-6 z-10 flex flex-col flex-wrap items-end gap-6',
            show ?
              'animate-in fade-in zoom-in-90 fill-mode-forwards'
            : 'animate-out fade-out zoom-out-90 fill-mode-forwards',
          )}
        >
          <Button
            title="Revert Changes"
            className="max-w-fit px-2 shadow-md"
            form={form}
            type={form ? 'reset' : 'button'}
            loading={isSubmitting}
            onClick={onReset}
            ref={resetRef}
          >
            <ArrowUturnLeftIcon className="h-5 w-5" aria-hidden="true" />
          </Button>
          <Button
            variant="primary"
            className="min-w-[6rem] p-4 shadow-md"
            form={form}
            type={form ? 'submit' : 'button'}
            loading={isSubmitting}
            onClick={() => onSubmit?.()}
          >
            <DocumentCheckIcon className="h-5 w-5" aria-hidden="true" /> Save
          </Button>
        </div>
      </Transition>
      <ConfirmModal
        title="Unsaved Changes"
        show={blocker.state === 'blocked'}
        prompt="You have unsaved changes. Do you wish to proceed and discard your changes?"
        confirmText="Discard Changes"
        cancelText="Return to Page"
        onConfirm={() => {
          resetRef.current?.click();
          blocker.proceed?.();
        }}
        onCancel={() => {
          // ? maybe this could instead just offer to save the changes; however, this would also trigger when dismissing the modal outside of the buttons
          blocker.reset?.();
        }}
      />
    </>
  );
}
