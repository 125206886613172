import {
  faAddressBook,
  faSwapArrows,
  faUserSecret,
} from '@fortawesome/pro-light-svg-icons';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import Card from '~/components/Card';
import Icon from '~/components/Icon';

/** Only visible on desktop view of the inbox-style list and details review. */
export default function EmptyDetails({
  title,
  description,
  claimType,
}: {
  title: string;
  description: string;
  claimType: `Return` | `Warranty` | `Shipping` | 'Registration';
}) {
  return (
    <Card>
      <div className="flex flex-col items-center justify-evenly gap-6 md:flex-row">
        <div className="space-y-2">
          <p className="text-2xl font-semibold">{title}</p>
          <p className="text-corso-gray-500">{description}</p>
        </div>
        {claimType === 'Return' && (
          <Icon
            icon={faSwapArrows}
            // icon base size seems to differ between FontAwesome and HeroIcons so this is different than the claim review icon size
            className="h-auto max-h-16 p-2 text-corso-gray-200 md:max-h-32 md:p-8"
            aria-hidden="true"
          />
        )}
        {claimType === 'Warranty' && (
          <CheckBadgeIcon
            className="max-h-32 p-2 text-corso-gray-200 md:max-h-48 md:p-8"
            aria-hidden="true"
          />
        )}
        {claimType === 'Registration' && (
          <Icon
            icon={faAddressBook}
            // icon base size seems to differ between FontAwesome and HeroIcons so this is different than the claim review icon size
            className="h-auto max-h-16 p-2 text-corso-gray-200 md:max-h-32 md:p-8"
            aria-hidden="true"
          />
        )}
        {claimType === 'Shipping' && (
          <Icon
            icon={faUserSecret}
            // icon base size seems to differ between FontAwesome and HeroIcons so this is different than the claim review icon size
            className="h-auto max-h-16 p-2 text-corso-gray-200 md:max-h-32 md:p-8"
          />
        )}
      </div>
    </Card>
  );
}
