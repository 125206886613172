import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { CrewMerchantUi } from 'corso-types';
import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Card from '~/components/Card';
import RelativeDateTime from '~/components/RelativeDateTime';
import Skeleton from '~/components/Skeleton';

function ClaimSkeleton() {
  return (
    <div className="flex flex-col gap-2 p-2">
      <Skeleton.Rectangle height="20px" width="60%" />
      <Skeleton.TextBlock lines={2} />
    </div>
  );
}

export default function ClaimsList({
  claims = [],
  isLoading = false,
}: {
  claims?: CrewMerchantUi.ClaimList;
  isLoading?: boolean;
}) {
  const location = useLocation();

  return (
    <Card flush>
      <Skeleton instances={1} skeleton={ClaimSkeleton} isLoading={isLoading}>
        <ul className="divide-y divide-corso-gray-200 overflow-y-auto lg:max-h-[1200px]">
          {/* // ? might need list virtualization; using `react-window` or `@tanstack/virtual-react`  `virtuoso` */}
          {/* // TODO error state */}
          {/* // TODO improve empty state */}
          {claims.length === 0 && !isLoading && (
            <li className="p-4">
              <p className="font-semibold">No Claims</p>
              <p className="text-sm text-corso-gray-500">
                There are no claims found.
              </p>
            </li>
          )}
          {claims.map((claim) => (
            <li key={`claim-${claim.id}`} className="group/claim">
              <NavLink
                className={({ isActive }) =>
                  twMerge(
                    'flex items-center justify-between gap-2 p-4 hover:bg-corso-gray-100 focus:bg-corso-gray-100 group-first/claim:rounded-t-md group-last/claim:rounded-b-md',
                    isActive &&
                      'bg-corso-gray-100 hover:bg-corso-gray-100 focus:bg-corso-gray-100',
                  )
                }
                to={{ pathname: `${claim.id}`, search: location.search }}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col justify-center">
                    <div className="flex items-center gap-2">
                      <p className="font-semibold">#{claim.externalId}</p>
                      {claim.lineItemHasError && (
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-corso-orange-700"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <span className="text-sm">{claim.claimRollup.name}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-xs text-corso-gray-500">
                      {claim.customerName ?? claim.customerEmail}
                    </p>
                    <p className="text-xs text-corso-gray-500">
                      Created{' '}
                      <RelativeDateTime dateTime={claim.createdOn} compact />
                    </p>
                  </div>
                </div>
                <ChevronRightIcon
                  className="h-5 w-5 text-corso-gray-500"
                  aria-hidden="true"
                />
              </NavLink>
            </li>
          ))}
        </ul>
      </Skeleton>
    </Card>
  );
}
