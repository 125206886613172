import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler, ReactNode, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button, { LinkButton } from '~/components/Button';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import DescriptionList from '~/components/DescriptionList';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import { SwitchInput, TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import Modal from '~/components/Modal';
import Skeleton from '~/components/Skeleton';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import {
  useReturnLocationDelete,
  useReturnLocations,
  useReturnLocationUpsert,
} from '~/hooks/useReturnLocations';

import { faBox } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pegasus } from 'corso-types';
import Address from '~/components/Address';
import Alert from '~/components/Alert';
import EmptyStateAction from '~/components/EmptyStateAction';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useStoreRules } from '~/hooks/useStoreRules';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  returnLocation,
  ReturnLocation,
  returnLocationCreate,
  ReturnLocationCreate,
  UpsertFormProps,
} from '~/types';
import { StoreRuleDisplay } from './storeRules/StoreRuleDisplay';

function ReturnLocationForm({
  show: showForm,
  onSubmit,
  onClose: closeForm,
  values,
}: UpsertFormProps<ReturnLocation>) {
  const { data: isReplenishInventoryWhenReturned } = useConfigSettings(
    (data) => data.returns.isReplenishInventoryWhenReturned,
  );
  const {
    storeUser: {
      store: { fulfillmentLocations },
    },
  } = useMerchantContext();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    resolver: zodResolver(values ? returnLocation : returnLocationCreate),

    values,
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((formValues) => {
      onSubmit(formValues);
      reset();
    })(event).catch(console.error);
  };

  return (
    <Modal
      title="Return Location"
      description="A location that represents a physical address where you can receive returns."
      show={showForm}
      onClose={closeForm}
    >
      <form className="flex flex-col gap-4" onSubmit={submitHandler}>
        <TextInput
          id="name"
          label="Internal Name"
          required
          {...register('name')}
          error={errors.name?.message}
          details="This is for internal use only and will not be visible to the customer."
        />

        <TextInput
          id="customs-signer-name"
          label="Customs Signer Name"
          {...register('customsSignerName')}
          error={errors.customsSignerName?.message}
          details="The name to use as the signer on customs forms, if applicable."
        />

        {isReplenishInventoryWhenReturned &&
          fulfillmentLocations.length > 0 && (
            <Controller
              name="fulfillmentLocationIdFromPlatform"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SimpleSelect
                  label="Shopify Restock Location"
                  placeholder="Select a Location"
                  details="Optionally, provide a Shopify location to replenish inventory when a return is approved. This is not related to the return location."
                  options={fulfillmentLocations.map((location) => ({
                    label: location.name,
                    value: location.idFromPlatform,
                  }))}
                  // TODO clearable selection; related https://github.com/radix-ui/primitives/issues/1569
                  value={value ?? ''} // ? might make this go from uncontrolled to controlled and would need to be addressed
                  onChange={onChange}
                  error={errors.fulfillmentLocationIdFromPlatform?.message}
                />
              )}
            />
          )}
        <hr className="my-2 border-t border-gray-200" />
        <TextInput
          id="name"
          details="Used as the name on the return label and packing slip, if applicable."
          label="Shipping Name"
          autoComplete="address-name"
          required
          {...register('address.name')}
          error={errors.address?.line1?.message}
        />

        <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2">
          <TextInput
            id="address.line1"
            label="Address Line 1"
            autoComplete="address-line1"
            required
            {...register('address.line1')}
            error={errors.address?.line1?.message}
          />
          <TextInput
            id="address.line2"
            label="Address Line 2"
            autoComplete="address-line2"
            {...register('address.line2')}
            error={errors.address?.line2?.message}
          />
          <TextInput
            id="address.city"
            label="City"
            autoComplete="address-level2"
            required
            {...register('address.city')}
            error={errors.address?.city?.message}
            details="City or town name"
          />
          <TextInput
            id="address.stateOrProvinceCode"
            label="State or Province Code"
            autoComplete="address-level1"
            details="2 or 3 letter state or province code"
            required
            {...register('address.stateOrProvinceCode')}
            error={errors.address?.stateOrProvinceCode?.message}
          />
          <TextInput
            id="address.postalCode"
            label="Postal Code"
            autoComplete="postal-code"
            details="Postal code or ZIP code"
            required
            {...register('address.postalCode')}
            error={errors.address?.postalCode?.message}
          />
          <TextInput
            id="address.countryCode"
            label="Country Code"
            autoComplete="country"
            details="2-letter country code"
            required
            {...register('address.countryCode')}
            error={errors.address?.countryCode?.message}
          />

          <TextInput
            id="phone"
            details="The phone number for the return location, required for some carriers."
            label="Phone"
            autoComplete="tel"
            {...register('address.phone')}
            error={errors.address?.phone?.message}
          />
        </div>

        <Button variant="primary" type="submit">
          {values ? 'Save' : 'Create'} Location
        </Button>
      </form>
    </Modal>
  );
}

function EditReturnLocation({
  location: { name, address },
  actions,
}: {
  location: ReturnLocation;
  actions?: ReactNode;
}) {
  return (
    <Disclosure
      renderSummary={
        <SimpleSummary>
          <div className="flex w-full flex-row items-center justify-between gap-2 pl-2">
            <h4 className="text-base font-medium text-corso-gray-800">
              {name}
            </h4>
            {actions}
          </div>
        </SimpleSummary>
      }
    >
      <div className="px-4">
        <DescriptionList
          descriptions={[
            {
              term: 'Address',
              details: (
                <>
                  {address.name}
                  <Address address={address} />
                </>
              ),
            },
          ]}
        />
      </div>
    </Disclosure>
  );
}

function ReturnLocationSkeleton() {
  return (
    <div className="flex gap-2">
      <Skeleton.Rectangle height="36px" width="100%" />
      <Skeleton.Rectangle height="36px" width="44px" />
      <Skeleton.Rectangle height="36px" width="44px" />
    </div>
  );
}

function DeleteLocation({ location }: { location: ReturnLocation }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { mutate: deleteLocation } = useReturnLocationDelete();

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const confirmDelete = () => {
    deleteLocation(location);
    closeDeleteConfirmation();
  };

  return (
    <>
      <IconAction.Button
        icon={TrashIcon}
        title={`Delete ${location.name}`}
        onClick={handleDelete}
      />

      <ConfirmModal
        title="Delete Location"
        prompt={`Are you sure you want to delete ${location.name}?`}
        confirmText="Delete"
        cancelText="Cancel"
        show={showDeleteConfirmation}
        onConfirm={confirmDelete}
        onCancel={closeDeleteConfirmation}
      />
    </>
  );
}

function EditLocation({ location }: { location: ReturnLocation }) {
  const [showForm, setShowForm] = useState(false);
  const { mutate: saveLocation } = useReturnLocationUpsert();

  const editLocation = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const saveEdits = (edits: ReturnLocation) => {
    saveLocation(edits);
    closeForm();
  };

  return (
    <>
      <IconAction.Button
        icon={PencilIcon}
        title={`Edit ${location.name}`}
        onClick={editLocation}
      />
      <ReturnLocationForm
        show={showForm}
        values={location}
        onSubmit={saveEdits}
        onClose={closeForm}
      />
    </>
  );
}

function AddLocation() {
  const [showForm, setShowForm] = useState(false);
  const { data: locations = [] } = useReturnLocations();
  const { mutate: saveLocation } = useReturnLocationUpsert();
  const openForm = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const createLocation = (values: ReturnLocationCreate) => {
    saveLocation(values);
    closeForm();
  };

  return (
    <>
      {locations.length === 0 ?
        <EmptyStateAction.Button
          onClick={openForm}
          icon={<FontAwesomeIcon icon={faBox} />}
          label="Create a Return Location"
          description="Looks like you don't have any return locations yet. Create a return location, so you can configure Shipping Policies to accept returns."
        />
      : <Button
          variant="primary"
          onClick={openForm}
          size="intrinsic"
          className="mt-4"
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
          Add Location
        </Button>
      }
      <ReturnLocationForm
        show={showForm}
        onSubmit={createLocation}
        onClose={closeForm}
      />
    </>
  );
}

function ReturnShippingConfigAutomationRules() {
  const { data: storeRules } = useStoreRules();

  const returnShipmentPolicyAutomationRules =
    storeRules?.filter((sr) => sr.rule.event.type === 'returnShippingConfig') ??
    [];

  const createReturnShipmentPolicy = `../automations/create?${new URLSearchParams(
    {
      eventType: Pegasus.EventType.returnShippingConfig,
      returnTo: '../../shipping-policies',
    },
  ).toString()}`;

  return (
    <Card>
      <Card.Title as="h2">Return Shipping Policy Automations</Card.Title>
      <Card.Description>
        Shipping policy automations determine whether to offer a packing slip or
        return label, based on the customer&apos;s country and other conditions.
      </Card.Description>
      {!returnShipmentPolicyAutomationRules?.length && (
        <Alert
          variant="warning"
          title="No Return Shipping Policies"
          message="You currently do not have any return shipping policy automations, in order for your store to accept returns you must create a return shipping policy automation."
        />
      )}
      <ul className="flex flex-col gap-2">
        {returnShipmentPolicyAutomationRules.map((storeRule) => (
          <li key={storeRule.id}>
            <StoreRuleDisplay
              storeRule={storeRule}
              actions={
                <div className="flex items-center gap-2">
                  <SwitchInput
                    disabled
                    labelVisuallyHidden
                    checked={storeRule.isEnabled}
                    id={`isEnabled-${storeRule.id}`}
                    label={`${storeRule.name} isEnabled`}
                  />
                  <IconAction.Link
                    title={`Edit ${storeRule.name} Rule`}
                    icon={PencilIcon}
                    to={`../automations/edit/${storeRule.id}?${new URLSearchParams(
                      {
                        returnTo: '../../shipping-policies',
                      },
                    ).toString()}`}
                  />
                </div>
              }
            />
          </li>
        ))}
      </ul>

      <div className="justify-s flex">
        <LinkButton
          variant="primary"
          title="Add A new Automation"
          to={createReturnShipmentPolicy}
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
          Add Policy Automation
        </LinkButton>
      </div>
    </Card>
  );
}

export default function ShippingPolicySettings() {
  const { data: returnLocations = [], isLoading } = useReturnLocations();

  return (
    <div className="flex flex-col gap-5">
      <Card>
        <Card.Title as="h2">Return Locations</Card.Title>
        <Card.Description>
          Locations represent physical addresses where you can receive returns,
          they are used in tandem with Shipping Policies to determine where to
          send returns.
        </Card.Description>
        <Skeleton
          isLoading={isLoading}
          instances={1}
          skeleton={ReturnLocationSkeleton}
        >
          <ul className="flex flex-col gap-2">
            {returnLocations.map((rl) => (
              <li key={rl.id}>
                <EditReturnLocation
                  location={rl}
                  actions={
                    <div className="flex flex-row gap-2">
                      <EditLocation location={rl} />
                      <DeleteLocation location={rl} />
                    </div>
                  }
                />
              </li>
            ))}
          </ul>
          <AddLocation />
        </Skeleton>
      </Card>

      {!!returnLocations.length && <ReturnShippingConfigAutomationRules />}
    </div>
  );
}
