import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import {
  ArrowTopRightOnSquareIcon,
  ArrowUturnLeftIcon,
  EnvelopeIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  CrewClaimResolutionMethodEnum,
  CrewClaimRollupStatusCode,
  CrewMerchantUi,
  FinalizationEmailTypeEnum,
} from 'corso-types';
import { FormEventHandler, useMemo, useState } from 'react';
import { Controller, useForm, useFormState, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import api from '~/api';
import Button from '~/components/Button';
import ConfirmModal from '~/components/ConfirmModal';
import ContentWrapper from '~/components/ContentWrapper';
import {
  SupportingText,
  SwitchInput,
  TextAreaInput,
  TextInput,
} from '~/components/field';
import IconAction from '~/components/IconAction';
import Menu from '~/components/Menu';
import Modal from '~/components/Modal';
import SpinnerIcon from '~/components/SpinnerIcon';
import { Label } from '~/components/ui/primitives/Label';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import { useStoreId } from '~/hooks/useStoreId';
import { useToast } from '~/hooks/useToast';
import {
  ClaimReview,
  isMetaStatus,
  isResolvedAs,
  reviewableResolutionMethods,
  ReviewMetaStatus,
  unsupportedResolutionMethods,
  useClaimReviewContext,
  useInvalidateClaimReview,
} from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';

type EmailTemplate =
  CrewMerchantUi.SettingsConfig['email']['storeEmailTemplates']['finalizeEmailTemplates'][number];

function FinalizeClaim() {
  const storeId = useStoreId();
  const { claimId } = useParams();
  const { claimReview } = useClaimReviewContext();
  const { userFullName } = useMerchantContext();

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const queryClient = useQueryClient();

  // TODO review and see what happens when part of a claim failed to finalize, and we retry
  const { mutate: finalizeClaim, isPending } = useMutation({
    mutationKey: ['claimFinalize', { claimId, storeId }],
    mutationFn: ({
      claim,
      isNotifyCustomerByEmailEnabled,
      notifyCustomerByEmail,
    }: ClaimReview) => {
      const { reviewLineItems, id, ...otherClaimData } = claim;

      const allReviewLineItems = reviewableResolutionMethods.flatMap(
        (resolutionMethod) => reviewLineItems[resolutionMethod],
      );

      return api
        .store(storeId)
        .claim(`${id}`, userFullName)
        .finalize({
          claim: {
            ...otherClaimData,
            id,
            claimLineItems: {
              approved: {
                refunds: allReviewLineItems
                  .filter(isResolvedAs(CrewClaimResolutionMethodEnum.refund))
                  .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                    ...claimLineItem,
                    noteToCustomer: noteToCustomer ?? undefined,
                    ...reviewMetadata.approval,
                  })),
                giftCards: allReviewLineItems
                  .filter(isResolvedAs(CrewClaimResolutionMethodEnum.giftCard))
                  .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                    ...claimLineItem,
                    noteToCustomer: noteToCustomer ?? undefined,
                    ...reviewMetadata.approval,
                  })),
                variantExchanges: allReviewLineItems
                  .filter(
                    isResolvedAs(CrewClaimResolutionMethodEnum.variantExchange),
                  )
                  .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                    ...claimLineItem,
                    noteToCustomer: noteToCustomer ?? undefined,
                    ...reviewMetadata.approval,
                  })),
                replacementOrders: allReviewLineItems
                  .filter(
                    isResolvedAs(
                      CrewClaimResolutionMethodEnum.replacementOrder,
                    ),
                  )
                  .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                    ...claimLineItem,
                    noteToCustomer: noteToCustomer ?? undefined,
                    ...reviewMetadata.approval,
                  })),
              },
              denied: allReviewLineItems
                .filter(isMetaStatus(ReviewMetaStatus.denying))
                .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                  ...claimLineItem,
                  noteToCustomer: noteToCustomer ?? undefined,
                  ...reviewMetadata.denial,
                })),
            },
          },
          ...(isNotifyCustomerByEmailEnabled && { notifyCustomerByEmail }),
        });
    },
    onSettled: () => {
      closeModal();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['claim', { claimId, storeId }, userFullName],
        data,
      );
      // * invalidate the claims query to update the  claims list
      return queryClient.invalidateQueries({ queryKey: ['claims'] });
    },
  });

  // * There seemed to be a disconnect with claimReview formState as it was behind a render or wouldn't trigger the modal to re-render, using the useFormState hook seems to fix this
  const { errors } = useFormState({
    control: claimReview.control,
    name: [
      'notifyCustomerByEmail.subject',
      'notifyCustomerByEmail.header',
      'notifyCustomerByEmail.body',
    ],
  });
  const isEmailEnabled = useWatch({
    control: claimReview.control,
    name: 'isNotifyCustomerByEmailEnabled',
  });
  const { data: emailSettings } = useConfigSettings(({ email }) => email);
  const emailTemplateOptions = useMemo(
    () =>
      emailSettings?.storeEmailTemplates.finalizeEmailTemplates.filter(
        (template) =>
          template.type !== FinalizationEmailTypeEnum.claimNeedsMoreInfo,
      ) ?? [],
    [emailSettings?.storeEmailTemplates.finalizeEmailTemplates],
  );

  const onEmailTemplateChange = (template: EmailTemplate | null) => {
    if (!template) {
      claimReview.resetField('notifyCustomerByEmail.subject');
      claimReview.resetField('notifyCustomerByEmail.header');
      claimReview.resetField('notifyCustomerByEmail.body');
      return;
    }

    claimReview.setValue('notifyCustomerByEmail.subject', template.subject, {
      shouldDirty: true,
      shouldValidate: true,
    });
    claimReview.setValue('notifyCustomerByEmail.header', template.header, {
      shouldDirty: true,
      shouldValidate: true,
    });
    claimReview.setValue('notifyCustomerByEmail.body', template.body, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onSubmit = () => {
    claimReview
      .handleSubmit((data) => {
        finalizeClaim(data);
        claimReview.reset();
      })()
      .catch((error) => {
        console.error('Request Failed', error);
      });
  };

  const claim = useWatch({ control: claimReview.control, name: 'claim' });
  const hasUnsupportedReviewLineItems = Object.values(
    unsupportedResolutionMethods,
  ).some(
    (resolutionMethod) => !!claim.reviewLineItems[resolutionMethod].length,
  );

  const hasUndecidedReviewLineItems = reviewableResolutionMethods.some(
    (reviewableResolution) =>
      !!claim.reviewLineItems[reviewableResolution].some(
        ({ reviewMetadata }) =>
          reviewMetadata.status === ReviewMetaStatus.undecided,
      ),
  );

  return (
    <>
      <Button
        variant="success"
        className="w-full sm:w-auto"
        loading={claimReview.formState.isSubmitting || isPending}
        // TODO display tooltip or text within general info with reason why button is disabled, still missing for undecided line items
        disabled={hasUnsupportedReviewLineItems || hasUndecidedReviewLineItems}
        onClick={() => setShowModal(true)}
      >
        Finalize Claim
        {/* // ? might want an info icon with popover to better clarify what "finalize" means; i.e. submitting all selections across all line items */}
      </Button>
      <Modal
        show={showModal}
        title="Finalize Claim"
        onClose={closeModal}
        actions={
          <>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              variant="success"
              onClick={onSubmit}
              loading={claimReview.formState.isSubmitting || isPending}
            >
              Finalize Claim
              {/* // ? possibly adjust button to morph on field state */}
            </Button>
          </>
        }
      >
        <form className="flex flex-col gap-4">
          <Controller
            control={claimReview.control}
            name="isNotifyCustomerByEmailEnabled"
            render={({ field: { onChange, value } }) => (
              <SwitchInput
                id="notify-customer"
                label="Notify Customer by Email"
                checked={value}
                onChange={onChange}
              />
            )}
          />
          {isEmailEnabled && (
            <div className="flex flex-col gap-4">
              {emailTemplateOptions.length > 0 && (
                <Controller
                  control={claimReview.control}
                  name="notifyCustomerByEmail.emailType"
                  render={({ field: { value, onChange } }) => (
                    <SimpleSelect
                      label="Email Template"
                      options={emailTemplateOptions.map((template) => ({
                        label: template.name,
                        value: template.type,
                      }))}
                      value={value}
                      onChange={(templateType) => {
                        const template = emailTemplateOptions.find(
                          ({ type }) => type === templateType,
                        );
                        if (!template) {
                          console.error(
                            new Error(
                              `Template Not Found for Type: ${templateType}`,
                            ),
                          );
                          return; // maybe this should throw the error instead of logging and returning
                        }
                        onEmailTemplateChange(template);
                        onChange(templateType);
                      }}
                    />
                  )}
                />
              )}
              <TextInput
                id="notify-customer-subject"
                label="Subject"
                required
                {...claimReview.register('notifyCustomerByEmail.subject')}
                error={
                  errors.notifyCustomerByEmail?.subject &&
                  'Subject is required.'
                }
              />
              <TextInput
                id="notify-customer-header"
                label="Header"
                required
                {...claimReview.register('notifyCustomerByEmail.header')}
                error={
                  errors.notifyCustomerByEmail?.header && 'Header is required.'
                }
              />
              <TextAreaInput
                id="notify-customer-body"
                label="Body"
                required
                {...claimReview.register('notifyCustomerByEmail.body')}
                error={
                  errors.notifyCustomerByEmail?.body && 'Body is required.'
                }
              />
            </div>
          )}
          <div className="rounded-md border border-corso-gray-300 bg-corso-gray-50 p-4">
            <TextInput
              id="claim-level-note-to-customer"
              label="Note to Customer (Claim Status Page)"
              details="This note displays at the top of the customer's claim status page. This does not replace notes added for individual line items."
              {...claimReview.register('claim.noteToCustomer')}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

function NeedsMoreInfoEmail({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { storeUser, userFullName } = useMerchantContext();

  const { claimReview } = useClaimReviewContext();

  const { show: showToast } = useToast();

  const {
    claim: { id, externalId, shippingAddress, customerEmail },
  } = claimReview.getValues();

  const { data: emailSettings } = useConfigSettings(({ email }) => email);
  const emailTemplate = useMemo(
    () =>
      emailSettings?.storeEmailTemplates.finalizeEmailTemplates.find(
        (template) =>
          template.type === FinalizationEmailTypeEnum.claimNeedsMoreInfo,
      ),
    [emailSettings?.storeEmailTemplates.finalizeEmailTemplates],
  );

  const { register, handleSubmit, reset } = useForm<
    CrewMerchantUi.ClaimNeedsMoreInfo['notifyCustomerByEmail']
  >({
    defaultValues: {
      emailType: FinalizationEmailTypeEnum.claimNeedsMoreInfo,
      subject: emailTemplate?.subject ?? '',
      header: emailTemplate?.header ?? '',
      body: emailTemplate?.body ?? '',
    },
  });

  const closeAndReset = () => {
    onClose();
    reset();
  };

  const {
    mutate: sendEmail,
    isPending,
    isError,
  } = useMutation({
    mutationKey: ['claimNeedsMoreInfoEmail', id],
    mutationFn: (
      notifyCustomerByEmail: CrewMerchantUi.ClaimNeedsMoreInfo['notifyCustomerByEmail'],
    ) =>
      api
        .store(String(storeUser.storeId))
        .claim(`${id}`, userFullName)
        .requestInfo({
          notifyCustomerByEmail,
          claim: {
            id,
            externalId,
            shippingAddress,
            customerEmail,
          },
        }),
    onSuccess: () => {
      closeAndReset();
      showToast('Email Sent');
    },
  });
  const formId = 'needs-more-info-email-form';

  const onSubmit: FormEventHandler = (event) => {
    handleSubmit((data) => {
      sendEmail(data);
      reset();
    })(event).catch(console.error);
  };

  return (
    <Modal
      show={show}
      title="Request More Information"
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={closeAndReset}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
          >
            {isPending ?
              <SpinnerIcon />
            : 'Send'}
          </Button>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={onSubmit}
        className="flex flex-col gap-4"
      >
        <dl className="grid grid-cols-5 gap-2">
          <dt>
            <Label>Send From:</Label>
          </dt>
          <dd className="col-span-4">{emailSettings?.emailSendFrom}</dd>
          <dt>
            <Label>Send To:</Label>
          </dt>
          <dd className="col-span-4">{customerEmail}</dd>
        </dl>
        <TextInput
          id="notify-customer-subject"
          label="Subject"
          required
          {...register('subject', { required: true })}
        />
        <TextInput
          id="notify-customer-header"
          label="Header"
          required
          {...register('header', { required: true })}
        />
        <TextAreaInput
          id="notify-customer-body"
          label="Body"
          required
          {...register('body', { required: true })}
        />
        {isError && (
          <SupportingText error>
            Sorry, failed to send the email. Please try again.
          </SupportingText>
        )}
      </form>
    </Modal>
  );
}

function CustomerPayment({
  payment,
  show,
  onClose,
}: {
  payment: NonNullable<ClaimReview['claim']['customerPayment']>;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();
  const invalidateClaim = useInvalidateClaimReview();

  const { amount, currencyCode } = payment;

  const { mutate: refundPayment } = useMutation({
    mutationKey: [payment.id],
    mutationFn: () => api.store(storeId).payment.refund(`${payment.id}`),
    onSuccess: () => invalidateClaim(),
  });

  return (
    <ConfirmModal
      title="Refund Payment"
      prompt={`Are you sure you want to process a refund for ${formatter.currency(
        amount,
        currencyCode,
      )} on Stripe?`}
      confirmText="Refund"
      cancelText="Cancel"
      show={show}
      onConfirm={() => {
        refundPayment();
        onClose();
      }}
      onCancel={onClose}
    />
  );
}

function CloseClaim({
  show,
  onClose,
  claimId,
}: {
  claimId: number;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();

  const queryClient = useQueryClient();
  const invalidateClaim = useInvalidateClaimReview();

  const { userFullName } = useMerchantContext();

  const { mutate: closeClaim } = useMutation({
    mutationKey: [claimId],
    mutationFn: () =>
      api.store(storeId).claim(`${claimId}`, userFullName).close(),
    onSuccess: () =>
      Promise.all([
        invalidateClaim(),
        queryClient.invalidateQueries({
          queryKey: ['claims'],
        }),
      ]),
  });

  return (
    <ConfirmModal
      title="Permanently Cancel Claim"
      prompt="Cancelled claims cannot be reopened and nothing will be issued to the customer. A customer can still create a new claim against this order."
      confirmText="Cancel Claim"
      cancelText="Cancel"
      show={show}
      onConfirm={() => {
        closeClaim();
        onClose();
      }}
      onCancel={onClose}
    />
  );
}

export default function ClaimReviewActions() {
  const { claimReview } = useClaimReviewContext();
  const claimId = claimReview.getValues('claim.id');
  const customerPayment = claimReview.getValues('claim.customerPayment');
  const claimRollupCode = claimReview.getValues('claim.claimRollup.code');
  const platformAdminUrl =
    claimReview.getValues('claim.originalStoreOrder.platformAdminUrl') ?? '';
  const nameFromPlatform = claimReview.getValues(
    'claim.originalStoreOrder.orderNo',
  );

  const canCloseClaim =
    claimRollupCode !== CrewClaimRollupStatusCode.completed && claimId;

  const [showNeedsMoreInfo, setShowNeedsMoreInfo] = useState(false);
  const closeNeedsMoreInfo = () => setShowNeedsMoreInfo(false);

  const [showRefundPayment, setShowRefundPayment] = useState(false);
  const closeRefundPayment = () => setShowRefundPayment(false);

  const [showCloseClaim, setShowCloseClaim] = useState(false);
  const closeCloseClaim = () => setShowCloseClaim(false);

  return (
    <ContentWrapper className="w-full sm:w-auto">
      {/* // TODO have easy access to finalize mobile/desktop; i.e. fixed/sticky */}

      <div className="flex justify-end gap-2">
        {claimRollupCode !== CrewClaimRollupStatusCode.completed && (
          <FinalizeClaim />
        )}
        <Menu
          align="end"
          buttonAs={
            <IconAction.Button
              icon={EllipsisVerticalIcon}
              title="More Actions"
              className="bg-white text-corso-gray-800"
            />
          }
        >
          <Menu.ItemButton onClick={() => setShowNeedsMoreInfo(true)}>
            <EnvelopeIcon className="h-5 w-5" /> Request More Info
          </Menu.ItemButton>

          {platformAdminUrl && (
            <Menu.ItemNavLink to={platformAdminUrl} target="_blank">
              <ArrowTopRightOnSquareIcon className="h-5 w-5" />{' '}
              {nameFromPlatform ?
                `View Order ${nameFromPlatform}`
              : 'View Order'}
            </Menu.ItemNavLink>
          )}

          {canCloseClaim && (
            <Menu.ItemButton onClick={() => setShowCloseClaim(true)}>
              <XMarkIcon className="h-5 w-5" /> Cancel Claim
            </Menu.ItemButton>
          )}

          {customerPayment?.status === 'Captured' && (
            <Menu.ItemButton onClick={() => setShowRefundPayment(true)}>
              <ArrowUturnLeftIcon className="h-5 w-5" /> Refund On Stripe
            </Menu.ItemButton>
          )}
        </Menu>
        <NeedsMoreInfoEmail
          show={showNeedsMoreInfo}
          onClose={closeNeedsMoreInfo}
        />
        {canCloseClaim && (
          <CloseClaim
            show={showCloseClaim}
            onClose={closeCloseClaim}
            claimId={claimId}
          />
        )}
        {customerPayment && (
          <CustomerPayment
            payment={customerPayment}
            show={showRefundPayment}
            onClose={closeRefundPayment}
          />
        )}
      </div>
    </ContentWrapper>
  );
}
