import { ClaimReasonEnum, ClaimResolutionMethodEnum } from 'corso-types';
import { PropsWithChildren } from 'react';
import Card from '~/components/Card';
import { TextInput } from '~/components/field';
import InfoPopover from '~/components/InfoPopover';
import {
  DatePickerWithRange,
  getDefinedDateRange,
} from '~/components/ui/primitives/DateRangeWithPicker';
import { Label } from '~/components/ui/primitives/Label';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import useNavigateSearch from '~/hooks/useNavigateSearch';
import {
  shippingClaimReasonName,
  shippingClaimResolutionMethodName,
} from '~/utils/enumNameMaps';

export type ShippingClaimSearchProps = PropsWithChildren<{
  searchTerm: string;
  dateRange: {
    from: Date;
    to: Date;
  };
  reason: ClaimReasonEnum | 'all';
  resolutionMethod: ClaimResolutionMethodEnum | 'all';
}>;

function SharedPopover({ content }: { content: string }) {
  return (
    <InfoPopover title="Search Details">
      <InfoPopover.Prose>{content}</InfoPopover.Prose>
    </InfoPopover>
  );
}

/** Search updates the navigable URL, which should trigger updates in state. */
export default function ShippingClaimSearch({
  searchTerm,
  dateRange,
  reason,
  resolutionMethod,
  children,
}: ShippingClaimSearchProps) {
  const navigateSearch = useNavigateSearch();

  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const offeredClaimReasons =
    data?.isConfigured ? data.offeredClaimReasons : [];

  return (
    <Card>
      <div className="flex flex-col gap-2">
        <Label className="flex items-center gap-0.5">
          Search
          <SharedPopover
            content="Search by email, claim number, order number or customer
            name."
          />
        </Label>

        <TextInput
          id="search"
          label="Search"
          className="flex-grow"
          placeholder="Search claims"
          onChange={(e) => {
            // TODO consider debouncing this to avoid excessive network requests
            navigateSearch(new URLSearchParams({ searchTerm: e.target.value }));
          }}
          value={searchTerm}
          labelVisuallyHidden
        />
      </div>

      <SimpleSelect
        label="Reason"
        value={reason}
        options={[
          { value: 'all', label: 'All' } as const,
          ...offeredClaimReasons.map((offeredReason) => ({
            value: offeredReason,
            label: shippingClaimReasonName[offeredReason],
          })),
        ]}
        onChange={(claimReason) => {
          navigateSearch(
            new URLSearchParams({
              reason: claimReason,
            }),
          );
        }}
      />

      <SimpleSelect
        label={
          <div className="flex items-center gap-0.5">
            Resolution
            <SharedPopover content="Filter by customer requested resolution method." />
          </div>
        }
        value={resolutionMethod}
        options={[
          { value: 'all', label: 'All' } as const,
          ...Object.values(ClaimResolutionMethodEnum).map((value) => ({
            value,
            label: shippingClaimResolutionMethodName[value],
          })),
        ]}
        onChange={(method) => {
          navigateSearch(
            new URLSearchParams({
              resolutionMethod: method,
            }),
          );
        }}
      />

      <div className="flex flex-col gap-2">
        <Label>Created Date</Label>

        <DatePickerWithRange
          value={dateRange}
          onChange={(selectedDateRange) => {
            const definedDateRange = getDefinedDateRange(selectedDateRange); // TODO inform user that the range will default to the last 30 days if no range is selected
            navigateSearch(
              new URLSearchParams({
                startDate: definedDateRange.from.toISOString(),
                endDate: definedDateRange.to.toISOString(),
              }),
            );
          }}
        />
      </div>

      {children}
    </Card>
  );
}
