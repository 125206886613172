import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/20/solid';
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  Cog6ToothIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import {
  CrewClaimTypeEnum,
  crewClaimTypeEnumPluralizedName,
  isTruthy,
  RoleCode,
} from 'corso-types';

import { differenceInDays } from 'date-fns';
import { ForwardRefExoticComponent, SVGProps, useMemo, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { NavLink, Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import CorsoIcon from '~/components/CorsoIcon';
import IconAction from '~/components/IconAction';
import Menu from '~/components/Menu';
import NoSubscription from '~/components/NoSubscription';
import { Combobox, ComboboxOption } from '~/components/ui/Combobox';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import { useAuthenticationContext } from '~/providers/AuthenticationProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';

function UserImage() {
  const { auth0User } = useAuthenticationContext();

  return !auth0User.picture ?
      <UserCircleIcon title={auth0User.name} className="h-9 w-9 text-white" />
    : <img
        className="h-9 w-9 rounded-full"
        src={auth0User.picture}
        alt={auth0User.name}
      />;
}

function UserWarningAppBanner() {
  const { user, storeUser } = useMerchantContext();

  //! temporary sanity check as multiple people are using this login during the Beta
  const show = storeUser.roleCode === RoleCode.corsoAdmin;

  const currentStore = storeUser.store.name;

  if (!show) return null;

  return (
    <div
      className="flex items-center justify-center gap-x-6 bg-corso-red-700 px-6 py-2.5 sm:px-3.5"
      data-testid="user-warning-app-banner"
    >
      <p className="text-sm leading-6 text-white">
        Logged in as <strong className="font-semibold">{user.email}</strong> on{' '}
        <strong className="font-semibold">{currentStore}</strong>
      </p>
    </div>
  );
}

function AppUninstalledBanner() {
  const {
    storeUser: {
      store: { isCrewInstalled },
    },
  } = useMerchantContext();
  if (isCrewInstalled) return null;
  return (
    <div
      className="flex items-center justify-center gap-x-6 bg-corso-red-600 px-6 py-2.5 sm:px-3.5"
      data-testid="user-warning-app-banner"
    >
      <p className="text-sm leading-6 text-white">
        The Corso app has been uninstalled from your store. Reinstall to
        continue using the app.
      </p>
    </div>
  );
}

/** Applies for any invoice definition, but is most prominent for free trials. */
export const EXPIRATION_WARNING_DAYS = 14;

function AppExpiringBanner() {
  const {
    storeUser: {
      store: { invoiceDefinition },
    },
  } = useMerchantContext();

  if (!invoiceDefinition) return null;

  const { endDate } = invoiceDefinition;

  const daysUntilExpiration = differenceInDays(new Date(endDate), new Date());

  if (daysUntilExpiration > EXPIRATION_WARNING_DAYS) return null;

  const days = daysUntilExpiration === 1 ? 'day' : 'days';

  return (
    <div
      className="flex justify-center gap-x-6 bg-corso-blue-800 px-6 py-2.5 sm:px-3.5"
      data-testid="subscription-expiring-banner"
    >
      <p className="text-sm leading-6 text-white">
        You have <strong className="font-bold">{daysUntilExpiration}</strong>{' '}
        {days}
        {` `}
        remaining.{` `}
        <a
          className="underline hover:underline"
          href="https://corso.com/book-a-time"
          target="_blank"
          rel="noreferrer"
        >
          Contact Us
        </a>{' '}
        to continue using the Corso App, free forever.
      </p>
    </div>
  );
}

/**
 * TODO needs a variant/customization to look like it belongs in the navigation
 * ? maybe display as a morphing button using the the `building-storefront` icon that changes on focus/hover to display the dropdown; inspired by Shopify's updated UI
 */
function StoreUserSelection() {
  const { user, storeUser, changeStoreUser } = useMerchantContext();

  const { storeUserRoles } = user;

  const storeOptions = useMemo(
    () =>
      storeUserRoles
        .map(
          (storeUserRole) =>
            ({
              value: `${storeUserRole.store.id}`,
              searchValue: storeUserRole.store.name,
              label: storeUserRole.store.name,
            }) satisfies ComboboxOption,
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    [storeUserRoles],
  );

  if (storeOptions.length === 1) {
    // don't need to worry about switching if there's only one store user option
    return (
      <div className="px-3 py-2 text-base font-medium text-white lg:text-sm">
        {storeOptions[0]?.label ?? 'No Store Name'}
      </div>
    );
  }

  return (
    <Combobox
      label="Select Store"
      options={storeOptions}
      placeholder="Search for a Store"
      value={`${storeUser.store.id}`}
      onChange={(storeId) =>
        storeId !== undefined && changeStoreUser(Number.parseInt(storeId, 10))
      }
    />
  );
}

const activeLinkStyles = 'bg-corso-gray-700 text-white';
const baseLinkStyles =
  'text-corso-gray-300 hover:bg-corso-gray-700 hover:text-white rounded px-3 py-2 text-sm font-medium';

// this might need support through a `kind` for link vs button actions
type MoreActionLink = {
  text: string;
  icon: ForwardRefExoticComponent<SVGProps<SVGSVGElement>>; // ? possibly improve to support other icon libraries or raw SVG elements // ? possibly revise to be _any_ `ReactNode`, but that then requires icons to be styled appropriately with `aria-hidden` set to `true`
  /** Should be unique, as it's used for the `key` when displayed. */
  to: string;
  target?: string;
};

// ? maybe this part of the header/navigation on mobile should position some links near the bottom of the screen to separate them visually, similar to a sidebar
function NavigationLinks({ close }: { close?: () => void }) {
  const { storeUser } = useMerchantContext();

  const isAdminUser =
    storeUser.roleCode === RoleCode.admin ||
    storeUser.roleCode === RoleCode.corsoAdmin;

  const {
    isReturnsEnabled,
    isWarrantyEnabled,
    isShippingProtectionEnabled,
    isRegistrationEnabled,
  } = useEnabledClaimType();

  const showClaimCreate =
    !!isReturnsEnabled || !!isWarrantyEnabled || !!isShippingProtectionEnabled;

  const moreActions = useMemo(
    () =>
      // * when adding a link here double check desktop/mobile display to ensure it looks decent
      [
        {
          text: 'Help',
          icon: QuestionMarkCircleIcon,
          to: 'https://help.corso.com/crew',
          target: '_blank',
        },
        isAdminUser && {
          text: 'Settings',
          icon: Cog6ToothIcon,
          to: `/${storeUser.storeId}/settings`,
        },

        showClaimCreate && {
          text: 'Create Claim',
          icon: PlusIcon,
          to: `/${storeUser.storeId}/claims/create`,
        },
        isAdminUser && {
          text: 'Billing',
          icon: DocumentTextIcon,
          to: `/${storeUser.storeId}/billing`,
        },
      ].filter(isTruthy) satisfies MoreActionLink[],
    [storeUser.storeId, isAdminUser, showClaimCreate],
  );

  return (
    <div className="flex flex-col gap-2 lg:flex-row lg:items-center lg:gap-4">
      {isReturnsEnabled && (
        <NavLink
          to={`claims/${CrewClaimTypeEnum.return.toLowerCase()}`}
          onClick={close}
          className={({ isActive }) =>
            twMerge(baseLinkStyles, isActive && activeLinkStyles)
          }
        >
          {crewClaimTypeEnumPluralizedName[CrewClaimTypeEnum.return]}
        </NavLink>
      )}
      {isWarrantyEnabled && (
        <NavLink
          to={`claims/${CrewClaimTypeEnum.warranty.toLowerCase()}`}
          onClick={close}
          className={({ isActive }) =>
            twMerge(baseLinkStyles, isActive && activeLinkStyles)
          }
        >
          {crewClaimTypeEnumPluralizedName[CrewClaimTypeEnum.warranty]}
        </NavLink>
      )}

      {isShippingProtectionEnabled && (
        <NavLink
          to="claims/shipping"
          onClick={close}
          className={({ isActive }) =>
            twMerge(baseLinkStyles, isActive && activeLinkStyles)
          }
        >
          Shipping
        </NavLink>
      )}

      {isRegistrationEnabled && (
        <NavLink
          to="registrations"
          onClick={close}
          className={({ isActive }) =>
            twMerge(baseLinkStyles, isActive && activeLinkStyles)
          }
        >
          Registrations
        </NavLink>
      )}

      <NavLink
        key="analytics"
        to="analytics"
        className={({ isActive }) =>
          twMerge(baseLinkStyles, isActive && activeLinkStyles)
        }
        onClick={close}
      >
        Analytics
      </NavLink>

      <div className="order-first lg:order-none lg:ml-auto">
        <StoreUserSelection />
      </div>

      <div className="flex flex-col gap-2 lg:flex-row-reverse lg:items-center">
        <div className="flex flex-col gap-2 lg:hidden">
          {moreActions.map(({ text, to, target }) => (
            <NavLink
              key={to}
              to={to}
              target={target}
              className={twMerge(baseLinkStyles, 'flex gap-2')}
              onClick={close}
            >
              {text}
            </NavLink>
          ))}
        </div>
        <div className="hidden items-center lg:flex">
          <Menu
            align="end"
            buttonAs={
              <IconAction.Button
                icon={EllipsisVerticalIcon}
                title="More Actions"
                className={twMerge(
                  baseLinkStyles,
                  'relative hidden p-2 ring-0 lg:block lg:rounded-full',
                )}
              />
            }
          >
            {moreActions.map(({ text, icon: Icon, to, target }) => (
              <Menu.ItemNavLink key={to} to={to} target={target}>
                <Icon className="h-5 w-5" aria-hidden="true" />
                {text}
              </Menu.ItemNavLink>
            ))}
          </Menu>
        </div>

        <NavLink
          to="/sign-out"
          className={twMerge(baseLinkStyles, 'group lg:rounded-full lg:p-0')}
        >
          <span className="lg:sr-only">Sign Out</span>
          <div className="hidden rounded-full lg:block">
            <div className="hidden rounded-full bg-corso-gray-700 p-2 text-white group-focus-within:block group-hover:block">
              <ArrowRightOnRectangleIcon className="h-5 w-5 text-white" />
            </div>
            <div className="group-focus-within:hidden group-hover:hidden">
              <UserImage />
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

function AppNavigation() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="sticky top-0 z-30 bg-corso-gray-900">
      <div
        className={twMerge(
          isOpen && 'fixed inset-0 bg-corso-gray-900 lg:static lg:inset-auto',
        )}
      >
        <div className="mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
          <div>
            <div className="flex h-16 items-center justify-between px-4 sm:px-0">
              <div className="mr-10 flex-shrink-0 text-white">
                <CorsoIcon />
              </div>

              <div className="hidden flex-1 lg:block">
                <NavigationLinks />
              </div>

              <div className="-mr-2 flex lg:hidden">
                {/* Mobile menu button */}
                <button
                  type="button"
                  onClick={() => setIsOpen((current) => !current)}
                  className="inline-flex items-center justify-center rounded-md bg-corso-gray-900 p-2 text-corso-gray-500 hover:bg-corso-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">
                    {isOpen ? 'Open' : 'Close'} main menu
                  </span>
                  {isOpen ?
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="h-full px-2 py-4 lg:hidden"
          hidden={!isOpen}
          aria-expanded={!isOpen}
        >
          <NavigationLinks close={() => setIsOpen(false)} />
        </div>
      </div>
    </nav>
  );
}

/** Primary layout for the application. */
export default function AppLayout() {
  const {
    storeUser: { store },
  } = useMerchantContext();

  const isSubscriptionMissingOrExpired =
    store.invoiceDefinition?.endDate &&
    new Date(store.invoiceDefinition.endDate) < new Date();

  if (isSubscriptionMissingOrExpired) return <NoSubscription />;

  return (
    <div className="relative">
      <UserWarningAppBanner />
      <AppUninstalledBanner />
      <AppExpiringBanner />
      <AppNavigation />
      <main>
        <Outlet />
        <Toaster position="bottom-right" />
      </main>
    </div>
  );
}
