import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { CrewMerchantUi, helperSchema } from 'corso-types';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import api from '~/api';
import Alert from '~/components/Alert';
import Button from '~/components/Button';
import Card from '~/components/Card';
import { EmailInput, TextInput } from '~/components/field';
import Page from '~/components/Page';
import { useStoreId } from '~/hooks/useStoreId';
import { ErrorResponse } from '~/utils/error';

const claimCreateOrderLookupSchema = z.object({
  email: z.string().email(),
  orderNumber: helperSchema.nonEmptyString,
});

export default function ClaimCreateLookup({
  onOrderLookup,
}: {
  onOrderLookup?: (order: CrewMerchantUi.CrewOrder) => void;
}) {
  const storeId = useStoreId();

  const {
    isPending,
    mutateAsync,
    isError,
    error: mutationError,
  } = useMutation({
    mutationFn: api.store(storeId).order.get,
  });

  const { register, formState, handleSubmit } = useForm<
    z.infer<typeof claimCreateOrderLookupSchema>
  >({
    resolver: zodResolver(claimCreateOrderLookupSchema),
  });

  return (
    <Page headline="Order Lookup" variant="narrow">
      <Card>
        <Card.Title>Order Lookup</Card.Title>
        <form
          onSubmit={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- handled elsewhere
            handleSubmit(
              (fieldValues) => mutateAsync(fieldValues).then(onOrderLookup),
              /* eslint-disable-next-line no-console */ // TODO consider allowing `warn`; log field errors to console
              (fieldErrors) => console.warn(fieldErrors),
            )(e).catch((error) => {
              // no-op on ErrorResponse, because they're handled and displayed elsewhere
              if (error instanceof ErrorResponse) return;
              console.error('Unhandled Order Lookup Error', error);
            });
          }}
        >
          <div className="space-y-4">
            <EmailInput
              id="customer-email"
              label="Email"
              placeholder="john.doe@example.com"
              required
              {...register('email')}
              error={formState.errors.email?.message}
            />
            <TextInput
              id="order-number"
              label="Order Number"
              placeholder="#12345"
              required
              {...register('orderNumber')}
              error={formState.errors.orderNumber?.message}
            />
            <Button
              type="submit"
              size="intrinsic"
              variant="primary"
              loading={isPending}
            >
              Lookup Order
            </Button>
            {isError &&
              (mutationError instanceof ErrorResponse ?
                <Alert message={mutationError.message} />
              : <Alert
                  variant="danger"
                  message="Something went wrong. Please try again."
                />)}
          </div>
          {/* // ? maybe support idFromPlatform lookup too */}
        </form>
      </Card>
    </Page>
  );
}
