import {
  ArrowPathIcon,
  EyeIcon,
  PencilIcon,
  PlusIcon,
  RectangleStackIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { isTruthy } from 'corso-types';
import { FormEventHandler, ReactNode, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '~/components/Button';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import DescriptionList, { Description } from '~/components/DescriptionList';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import EmptyStateAction from '~/components/EmptyStateAction';
import { SwitchInput, TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import LineItem from '~/components/LineItem';
import Modal from '~/components/Modal';
import RelativeDateTime from '~/components/RelativeDateTime';
import Skeleton from '~/components/Skeleton';
import SpinnerIcon from '~/components/SpinnerIcon';
import { MultiSelect, MultiSelectOption } from '~/components/ui/MultiSelect';
import { useProductCollections } from '~/hooks/useProductCollections';
import {
  useProductGroupDelete,
  useProductGroupProducts,
  useProductGroupRefresh,
  useProductGroups,
  useProductGroupUpsert,
} from '~/hooks/useProductGroups';
import { useProductTags } from '~/hooks/useProductTags';
import { useProductTypes } from '~/hooks/useProductTypes';
import {
  ProductGroup,
  ProductGroupCreate,
  productGroupCreateSchema,
  productGroupSchema,
  UpsertFormProps,
} from '~/types';

// ? possibly elevate into `MultiSelect` for sharing
const multiSelectTransform = {
  toOptions: (options: { id: number; name: string }[]) =>
    options.map(
      ({ id, name }) =>
        ({ label: name, value: `${id}` }) satisfies MultiSelectOption,
    ),
  fromSelected: (
    options: { id: number; name: string }[],
    selected: MultiSelectOption[],
  ) =>
    selected
      .map((selection) =>
        options.find(
          (option) => option.id === Number.parseInt(selection.value, 10),
        ),
      )
      .filter(isTruthy),
};

function ProductGroupForm({
  show,
  onClose,
  onSubmit,
  values,
}: UpsertFormProps<ProductGroup>) {
  const update = !!values?.id;
  const schemaToUse = update ? productGroupSchema : productGroupCreateSchema;
  const { data: tags = [] } = useProductTags();

  const { data: types = [] } = useProductTypes();

  const { data: collections = [] } = useProductCollections();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    register,
  } = useForm({
    resolver: zodResolver(schemaToUse),
    defaultValues: {
      tags: [],
      types: [],
      collections: [],
    },
    values,
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((formValues) => {
      onSubmit(formValues);
      reset();
    })(event).catch(console.error);
  };

  return (
    <Modal
      title="Product Group"
      description="Create a group of products by selecting any number of tags and types."
      show={show}
      onClose={onClose}
    >
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <TextInput
          id="name"
          label="Name"
          details="The name of the product group in CREW, this name will be used when setting up other parts of the application."
          required
          {...register('name')}
          error={errors.name?.message}
        />

        <Controller
          control={control}
          name="isExchangeGroup"
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              id="exchange-group"
              label="Exchange group"
              details="If enabled, products within this group can be exchanged 1 for 1 regardless of cost."
              checked={value}
              onChange={onChange}
              error={errors.isExchangeGroup?.message}
            />
          )}
        />

        <Controller
          name="tags"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiSelect
              togglable
              label="Product tags"
              placeholder="Search for a tag"
              options={multiSelectTransform.toOptions(tags)}
              value={multiSelectTransform.toOptions(value)}
              onChange={(selected) =>
                onChange(multiSelectTransform.fromSelected(tags, selected))
              }
              details="Product tags to include in this group."
              error={errors.tags?.message}
            />
          )}
        />

        <Controller
          name="types"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiSelect
              togglable
              label="Product types"
              placeholder="Search for a product type"
              options={multiSelectTransform.toOptions(types)}
              value={multiSelectTransform.toOptions(value)}
              onChange={(selected) =>
                onChange(multiSelectTransform.fromSelected(types, selected))
              }
              details="Product types to include in this group."
              error={errors.types?.message}
            />
          )}
        />
        <Controller
          name="collections"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiSelect
              togglable
              label="Product collections"
              placeholder="Search for a product collection"
              options={multiSelectTransform.toOptions(collections)}
              value={multiSelectTransform.toOptions(value)}
              onChange={(selected) =>
                onChange(
                  multiSelectTransform.fromSelected(collections, selected),
                )
              }
              details="Product collections to include in this group."
              error={errors.types?.message}
            />
          )}
        />
        <Button variant="primary" type="submit">
          {update ? 'Save' : 'Create'} Product Group
        </Button>
      </form>
    </Modal>
  );
}

function ProductGroupDisplay({
  group: { name, types, tags, isExchangeGroup, collections },
  actions,
}: {
  group: ProductGroup;
  actions?: ReactNode;
}) {
  const descriptions: Description[] = [];

  if (tags.length > 0)
    descriptions.push({
      term: 'Tags',
      details: tags.map((t) => t.name).join(', '),
    });
  if (types.length > 0)
    descriptions.push({
      term: 'Types',
      details: types.map((t) => t.name).join(', '),
    });

  if (collections.length > 0)
    descriptions.push({
      term: 'Collections',
      details: collections.map((c) => c.name).join(', '),
    });

  descriptions.push({
    term: 'Exchange group',
    details: isExchangeGroup ? 'Yes' : 'No',
  });

  return (
    <Disclosure
      renderSummary={
        <SimpleSummary>
          <div className="flex w-full flex-row items-center justify-between gap-2 pl-2">
            <h4 className="text-base font-medium text-corso-gray-800">
              {name}
            </h4>
            {actions}
          </div>
        </SimpleSummary>
      }
    >
      <div className="mt-1 pl-4">
        <DescriptionList descriptions={descriptions} />
      </div>
    </Disclosure>
  );
}

function ProductGroupSkeleton() {
  return (
    <div className="flex gap-2">
      <Skeleton.Rectangle height="36px" width="100%" />
      <Skeleton.Rectangle height="36px" width="44px" />
      <Skeleton.Rectangle height="36px" width="44px" />
      <Skeleton.Rectangle height="36px" width="44px" />
    </div>
  );
}

function ViewProducts({
  groupName,
  groupId,
  lastUpdatedOn,
}: {
  groupName: string;
  groupId: number;
  lastUpdatedOn?: string;
}) {
  const [showProducts, setShowProducts] = useState(false);
  const { data: products = [], isLoading } = useProductGroupProducts(
    groupId,
    showProducts,
  );

  return (
    <>
      <IconAction.Button
        icon={EyeIcon}
        title={`View products in ${groupName}`}
        onClick={() => setShowProducts(true)}
      />

      <Modal
        title={groupName}
        description="All products that match type, collection and tag criteria (archived, deleted and draft products are not included)."
        show={showProducts}
        onClose={() => setShowProducts(false)}
      >
        {lastUpdatedOn && (
          <div className="mb-4 text-sm text-corso-gray-600">
            Refreshed <RelativeDateTime dateTime={lastUpdatedOn} />
          </div>
        )}

        {isLoading ?
          <div className="flex h-12 w-full items-center justify-center">
            <SpinnerIcon />
          </div>
        : <ul className="max-h-96 overflow-y-scroll text-sm font-semibold leading-6 text-corso-gray-800">
            {products.map(({ id, title, imgUrl }) => (
              <li key={id} className="px-4 py-5 sm:px-6">
                <LineItem name={title} imageUrl={imgUrl} />
              </li>
            ))}
          </ul>
        }
      </Modal>
    </>
  );
}

function RefreshProducts({
  groupName,
  groupId,
}: {
  groupName: string;
  groupId: number;
}) {
  const { isPending, mutate: refreshProducts } =
    useProductGroupRefresh(groupId);
  return (
    <IconAction.Button
      icon={ArrowPathIcon}
      title={`Refresh Products In ${groupName}`}
      onClick={() => refreshProducts()}
      loading={isPending}
    />
  );
}

function DeleteGroup({ group }: { group: ProductGroup }) {
  const { mutate: deleteProductGroup } = useProductGroupDelete();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirmDelete = () => {
    deleteProductGroup(group);
    setShowConfirmation(false);
  };

  return (
    <>
      <IconAction.Button
        icon={TrashIcon}
        title={`Delete ${group.name} group`}
        onClick={() => setShowConfirmation(true)}
      />

      <ConfirmModal
        title="Delete Group"
        prompt={`Are you sure you want to delete ${group.name}?`}
        confirmText="Delete"
        cancelText="Cancel"
        show={showConfirmation}
        onConfirm={confirmDelete}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
}

function EditGroup({ group }: { group: ProductGroup }) {
  const { mutate: saveGroup } = useProductGroupUpsert();
  const [showForm, setShowForm] = useState(false);
  const saveEdits = (edits: ProductGroup) => {
    saveGroup(edits);
    setShowForm(false);
  };

  return (
    <>
      <IconAction.Button
        icon={PencilIcon}
        title={`Edit ${group.name} group`}
        onClick={() => setShowForm(true)}
      />

      <ProductGroupForm
        show={showForm}
        onClose={() => setShowForm(false)}
        onSubmit={saveEdits}
        values={group}
      />
    </>
  );
}

function AddGroup() {
  const { data: groups = [] } = useProductGroups();
  const { mutate: saveGroup } = useProductGroupUpsert();
  const [showForm, setShowForm] = useState(false);

  const addGroup = (group: ProductGroupCreate) => {
    saveGroup(group);
    setShowForm(false);
  };

  return (
    <>
      {groups.length === 0 ?
        <EmptyStateAction.Button
          onClick={() => setShowForm(true)}
          icon={<RectangleStackIcon />}
          label="Create a product group"
          description="Product groups are used to group products together for easier management."
        />
      : <Button
          variant="primary"
          onClick={() => setShowForm(true)}
          className="md:max-w-fit"
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
          Add Group
        </Button>
      }
      <ProductGroupForm
        show={showForm}
        onClose={() => setShowForm(false)}
        onSubmit={addGroup}
      />
    </>
  );
}

export default function ProductGroupSettings() {
  const { data: groups = null, isLoading } = useProductGroups();

  return (
    <Card>
      <Card.Title as="h2">Product Groups</Card.Title>
      <Skeleton
        instances={1}
        skeleton={ProductGroupSkeleton}
        isLoading={isLoading}
      >
        <ul className="flex flex-col gap-2">
          {groups?.map((group) => (
            <li key={group.id}>
              <ProductGroupDisplay
                group={group}
                actions={
                  <div className="flex flex-row gap-2">
                    <RefreshProducts
                      groupName={group.name}
                      groupId={group.id}
                    />
                    <ViewProducts
                      groupName={group.name}
                      groupId={group.id}
                      lastUpdatedOn={group.lastUpdatedOn}
                    />
                    <EditGroup group={group} />
                    <DeleteGroup group={group} />
                  </div>
                }
              />
            </li>
          ))}
        </ul>
        <AddGroup />
      </Skeleton>
    </Card>
  );
}
