import {
  faAddressBook,
  faAt,
  faBadgeCheck,
  faBell,
  faBox,
  faFeather,
  faGrid2,
  faHatWizard,
  faPaintBrush,
  faPlugCirclePlus,
  faRectangleHistory,
  faSwapArrows,
  faUserGroupSimple,
  faUserSecret,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';

import {
  faAddressBook as faAddressBookSolid,
  faAt as faAtSolid,
  faBadgeCheck as faBadgeCheckSolid,
  faBell as faBellSolid,
  faBox as faBoxSolid,
  faFeather as faFeatherSolid,
  faGrid2 as faGrid2Solid,
  faHatWizard as faHatWizardSolid,
  faPaintBrush as faPaintBrushSolid,
  faPlugCirclePlus as faPlugCirclePlusSolid,
  faRectangleHistory as faRectangleHistorySolid,
  faSwapArrows as faSwapArrowsSolid,
  faUserGroupSimple as faUserGroupSimpleSolid,
  faUserSecret as faUserSecretSolid,
} from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import { isTruthy } from 'corso-types';
import React, { useMemo } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Page from '~/components/Page';
import { Badge } from '~/components/ui/primitives/Badge';
import { Transition } from '~/components/ui/primitives/Transition';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import { useSettingsActionsContext } from '~/providers/SettingsActionsProvider';

function SettingsNavigation() {
  const { isReturnOrWarrantyEnabled } = useEnabledClaimType();

  const settingsNavigation = useMemo(() => {
    const returnOrWarrantyItems = [
      {
        name: 'Automations',
        to: 'automations',
        icon: faHatWizard,
        activeIcon: faHatWizardSolid,
        badge: null,
      },
      {
        name: 'Registrations',
        badge: <Badge variant="beta">Beta</Badge>,
        to: 'registrations',
        icon: faAddressBook,
        activeIcon: faAddressBookSolid,
      },
      {
        name: 'Email',
        to: 'email',
        icon: faAt,
        activeIcon: faAtSolid,
        badge: null,
      },
      {
        name: 'Reasons',
        to: 'reasons',
        icon: faGrid2,
        activeIcon: faGrid2Solid,
        badge: null,
      },
      {
        name: 'Custom Fields',
        to: 'custom-fields',
        icon: faFeather,
        activeIcon: faFeatherSolid,
        badge: null,
      },
      {
        name: 'Product Groups',
        to: 'product-groups',
        icon: faRectangleHistory,
        activeIcon: faRectangleHistorySolid,
        badge: null,
      },
      {
        name: 'Notifications',
        to: 'notifications',
        icon: faBell,
        activeIcon: faBellSolid,
        badge: null,
      },
      {
        name: 'Shipping Policies',
        to: 'shipping-policies',
        icon: faBox,
        activeIcon: faBoxSolid,
        badge: null,
      },
    ]
      .filter(isTruthy)
      .map((i) => ({
        ...i,
        isEnabled: isReturnOrWarrantyEnabled,
      }));

    const navigationItems = [
      {
        name: 'Theme',
        to: 'theme',
        icon: faPaintBrush,
        activeIcon: faPaintBrushSolid,
        badge: null,
      },
      {
        name: 'Users',
        to: 'users',
        icon: faUserGroupSimple,
        activeIcon: faUserGroupSimpleSolid,
        badge: null,
      },
      {
        name: 'Integrations',
        to: 'integrations',
        icon: faPlugCirclePlus,
        activeIcon: faPlugCirclePlusSolid,
        badge: null,
      },
      {
        name: 'Returns',
        to: 'returns',
        icon: faSwapArrows,
        activeIcon: faSwapArrowsSolid,
        badge: null,
      },
      {
        name: 'Warranties',
        to: 'warranties',
        icon: faBadgeCheck,
        activeIcon: faBadgeCheckSolid,
        badge: null,
      },
      {
        name: 'Shipping Protection',
        to: 'shipping-protection',
        icon: faUserSecret,
        activeIcon: faUserSecretSolid,
        badge: null,
      },
      ...returnOrWarrantyItems,
    ].map((i) => ({ isEnabled: true, ...i }));

    return navigationItems.filter(Boolean) satisfies {
      isEnabled: boolean;
      name: string;
      badge: React.ReactNode | null;
      to: string;
      icon: IconDefinition;
      activeIcon: IconDefinition;
    }[];
  }, [isReturnOrWarrantyEnabled]);

  return (
    <nav className="flex flex-col overflow-hidden bg-white shadow-md lg:rounded-lg">
      {/* // ? should this be a `Disclosure` or `Menu` on mobile */}
      <ul className="flex flex-col">
        {settingsNavigation.map((navigation) => (
          <li key={navigation.name}>
            <NavLink
              to={navigation.to}
              className={({ isActive }) =>
                twMerge(
                  'group flex items-center justify-between p-4 text-sm font-semibold',
                  isActive ?
                    'border-s-2 border-corso-blue-600 bg-corso-gray-100 text-corso-blue-600'
                  : 'border-s-2 border-transparent text-corso-gray-800 hover:bg-corso-gray-100 hover:text-corso-blue-600 focus:bg-corso-gray-100',
                  !navigation.isEnabled && 'text-corso-gray-600',
                )
              }
            >
              {({ isActive }) => (
                <div className="flex items-center gap-2.5">
                  {isActive ?
                    <FontAwesomeIcon
                      icon={navigation.activeIcon}
                      fixedWidth
                      size="lg"
                    />
                  : <>
                      <FontAwesomeIcon
                        icon={navigation.activeIcon}
                        fixedWidth
                        size="lg"
                        className="hidden group-hover:block group-focus:block"
                      />
                      <FontAwesomeIcon
                        icon={navigation.icon}
                        fixedWidth
                        size="lg"
                        className="group-hover:hidden group-focus:hidden"
                      />
                    </>
                  }
                  {navigation.name} {navigation.badge ? navigation.badge : null}
                </div>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

function ActionItems() {
  const { actions } = useSettingsActionsContext();
  const incomplete = actions.filter((item) => !item.isComplete).length;

  return (
    <Transition show={!!incomplete}>
      <section
        className={twMerge(
          'overflow-hidden bg-white shadow-md lg:rounded-lg',
          incomplete ?
            'animate-in fade-in zoom-in-90 fill-mode-forwards'
          : 'animate-out fade-out zoom-out-90 fill-mode-forwards',
        )}
      >
        <h2 className="mx-4 mb-2 mt-4 text-lg font-medium">Action Items</h2>
        <p className="mx-4 text-xs text-corso-gray-500">
          To get started, complete the following items to configure your
          settings.{' '}
          <span>
            <a
              className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
              href="https://help.corso.com/crew#action-items-checklist"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          </span>
        </p>
        <ul className="my-4 flex flex-col">
          {actions.map((action) => (
            <li key={action.label}>
              <Link
                to={action.to}
                className="flex items-center justify-between gap-x-4 px-4 py-4 text-sm text-corso-gray-800 hover:bg-corso-gray-50 hover:text-corso-blue-600"
              >
                <div className="flex items-center gap-2">
                  {action.isComplete ?
                    <CheckCircleIcon
                      className="h-5 w-5 text-green-600"
                      aria-label="Completed"
                    />
                  : <svg
                      aria-hidden="true"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                      aria-label="Incomplete"
                    >
                      <circle cx="10" cy="10" r="8" />
                    </svg>
                  }

                  {action.label}
                </div>
                <ChevronRightIcon className="h-3 w-3 stroke-2" />
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </Transition>
  );
}

/** Sub-layout for `/settings` Pages */
export default function SettingsLayout() {
  // TODO need a skeleton zero state while settings are loading
  return (
    <Page headline="Settings">
      <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-[minmax(0,1fr)_minmax(0,3fr)]">
        {/* // * top-16 is a magic value so that it doesn't slip behind the app layout navigation */}
        <aside className="flex flex-col gap-4 lg:sticky lg:top-16">
          <ActionItems />
          <SettingsNavigation />
        </aside>
        <article>
          <Outlet />
        </article>
      </div>
    </Page>
  );
}
