type Props = { message: string };

export default function Snackbar({ message }: Props) {
  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-6 flex justify-center">
      <div className="pointer-events-auto overflow-hidden rounded-lg bg-black shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="flex items-center justify-between px-6 py-4">
          <p className="text-sm font-medium text-white">{message}</p>
        </div>
      </div>
    </div>
  );
}
