import { faCircleUser } from '@fortawesome/pro-solid-svg-icons';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import {
  LockClosedIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { RoleCode } from 'corso-types';
import { FormEventHandler, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '~/components/Button';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import { TextInput } from '~/components/field';
import Icon from '~/components/Icon';
import IconAction from '~/components/IconAction';
import Menu from '~/components/Menu';
import Modal from '~/components/Modal';
import Skeleton from '~/components/Skeleton';
import { Badge } from '~/components/ui/primitives/Badge';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  useResetUserPassword,
  useStoreUserDelete,
  useStoreUsers,
  useStoreUserUpsert,
} from '~/hooks/useStoreUsers';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  StoreUser,
  StoreUserCreate,
  storeUserCreateSchema,
  storeUserUpdateSchema,
  UpsertFormProps,
} from '~/types';

const roleTypeLabel = {
  [RoleCode.admin]: 'Admin',
  [RoleCode.staff]: 'Staff',
  [RoleCode.commerceApiAdmin]: 'Commerce API Admin',
} as const satisfies Record<Exclude<RoleCode, RoleCode.corsoAdmin>, string>;

const roleCodeOptions = [
  {
    value: RoleCode.staff,
    label: roleTypeLabel[RoleCode.staff],
  },
  {
    value: RoleCode.admin,
    label: roleTypeLabel[RoleCode.admin],
  },
];

function UserForm({
  show,
  onClose,
  onSubmit,
  values,
}: UpsertFormProps<StoreUser>) {
  const {
    storeUser: { storeId },
  } = useMerchantContext();
  const update = !!values?.id;
  const schemaToUse = update ? storeUserUpdateSchema : storeUserCreateSchema;
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    resolver: zodResolver(schemaToUse),
    values,
    defaultValues: {
      storeUserRole: {
        storeId,
      },
    },
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((formValues) => onSubmit(formValues))(event)
      .then(() => reset())
      .catch(console.error);
  };

  return (
    <Modal
      title={update ? 'Edit User' : 'Invite User'}
      description={
        update ?
          'Admins have full access to all settings, while staff can only view and edit claims.'
        : 'Create a new user for your store, and assign them a role. Admins have full access to all settings, while staff can only view and edit claims.'
      }
      show={show}
      onClose={onClose}
    >
      <form className="flex flex-col gap-4" onSubmit={submitHandler}>
        <TextInput
          id="firstName"
          label="First Name"
          required
          {...register('firstName')}
          error={errors.firstName?.message}
        />

        <TextInput
          id="lastName"
          label="Last Name"
          required
          {...register('lastName')}
          error={errors.lastName?.message}
        />

        {!update && (
          <TextInput
            id="email"
            label="Email"
            required
            {...register('email')}
            error={errors.email?.message}
          />
        )}

        <Controller
          name="storeUserRole.roleCode"
          control={control}
          render={({ field: { onChange, value } }) => (
            <SimpleSelect
              label="Role"
              placeholder="Select a role"
              options={roleCodeOptions}
              value={value}
              onChange={onChange}
              error={errors.storeUserRole?.roleCode?.message}
            />
          )}
        />
        <Button variant="primary" type="submit">
          {update ? 'Save' : 'Send Invite'}
        </Button>
      </form>
    </Modal>
  );
}

function InviteUser() {
  const [showForm, setShowForm] = useState(false);
  const { mutate: inviteUser } = useStoreUserUpsert();

  const openUserForm = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const submitHandler = (user: StoreUserCreate) => {
    inviteUser(user);
    closeForm();
  };

  return (
    <>
      <Button variant="primary" onClick={openUserForm} className="md:max-w-fit">
        <PlusIcon className="h-5 w-5" aria-hidden="true" />
        Invite User
      </Button>

      <UserForm show={showForm} onClose={closeForm} onSubmit={submitHandler} />
    </>
  );
}

function EditUser({
  user,
  show,
  onClose,
}: {
  user: StoreUser;
  show: boolean;
  onClose: () => void;
}) {
  const { mutate: updateUser } = useStoreUserUpsert();

  const submitHandler = (updates: StoreUser) => {
    updateUser(updates);
    onClose();
  };

  return (
    <UserForm
      values={user}
      show={show}
      onClose={onClose}
      onSubmit={submitHandler}
    />
  );
}

function DeleteUser({
  user,
  show,
  onClose,
}: {
  user: StoreUser;
  show: boolean;
  onClose: () => void;
}) {
  const { mutate: deleteUser } = useStoreUserDelete();
  const confirmDelete = () => {
    deleteUser(user);
    onClose();
  };

  return (
    <ConfirmModal
      title="Delete User"
      prompt={`Are you sure you want to delete ${user.email}? This will immediately revoke access to Crew.`}
      confirmText="Delete"
      cancelText="Cancel"
      show={show}
      onConfirm={confirmDelete}
      onCancel={onClose}
    />
  );
}

function UserDisplay({ user }: { user: StoreUser }) {
  const { email, storeUserRole, firstName, lastName } = user;
  const {
    user: { id: loggedInUserId },
  } = useMerchantContext();

  const { mutate: resetPassword } = useResetUserPassword();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const closeDeleteConfirmation = () => setShowDeleteConfirmation(false);

  const [showForm, setShowForm] = useState(false);
  const closeForm = () => {
    setShowForm(false);
  };

  const name = `${firstName} ${lastName}`;

  if (storeUserRole.roleCode === RoleCode.corsoAdmin) return null; // we shouldn't ever get users to list with a Corso Admin role, but skip them just in case // TODO adjust the API response to affirm this, so this can be removed

  return (
    <li className="group grid grid-cols-[auto_1fr_auto] items-center justify-between gap-4 px-2 py-3 odd:bg-white even:bg-corso-gray-50 sm:flex-row sm:px-4">
      <Icon icon={faCircleUser} className="size-10 text-corso-gray-400/80" />
      <div>
        <div className="flex flex-wrap gap-2">
          <p className="text-sm font-semibold leading-6 text-corso-gray-800">
            {name}
          </p>
          <Badge>{roleTypeLabel[storeUserRole.roleCode]}</Badge>
        </div>
        <a
          href={`mailto:${email}`}
          className="mt-1 flex truncate text-xs leading-5 text-corso-gray-500 hover:underline"
        >
          {email}
        </a>
      </div>
      <Menu
        align="end"
        buttonAs={
          <IconAction.Button
            icon={EllipsisVerticalIcon}
            className="bg-white"
            title={`Actions for ${name}`}
          />
        }
      >
        <Menu.ItemButton onClick={() => setShowForm(true)}>
          <PencilIcon className="h-5 w-5" /> Edit User
        </Menu.ItemButton>
        <Menu.ItemButton onClick={() => resetPassword(user)}>
          <LockClosedIcon className="h-5 w-5" /> Reset Password
        </Menu.ItemButton>
        {user.id !== loggedInUserId && (
          <Menu.ItemButton onClick={() => setShowDeleteConfirmation(true)}>
            <span className="text-corso-red-600">
              <TrashIcon className="h-5 w-5" /> Delete User
            </span>
          </Menu.ItemButton>
        )}
      </Menu>
      <EditUser user={user} show={showForm} onClose={closeForm} />
      <DeleteUser
        user={user}
        show={showDeleteConfirmation}
        onClose={closeDeleteConfirmation}
      />
    </li>
  );
}

function UserSkeleton() {
  return (
    <div className="flex flex-col justify-between gap-4 px-4 py-5 sm:flex-row sm:px-6">
      <div className="flex gap-x-2">
        <Skeleton.Circle diameter="36px" />
        <Skeleton.Rectangle height="49px" width="251px" />
      </div>
      <div className="flex flex-row items-center gap-2">
        <Skeleton.Rectangle width="44px" height="36px" />
        <Skeleton.Rectangle width="132px" height="36px" />
        <Skeleton.Rectangle width="44px" height="36px" />
      </div>
    </div>
  );
}

export default function UserSettings() {
  const { data: users = [], isLoading } = useStoreUsers();

  return (
    <Card>
      <Card.Title as="h2">Users</Card.Title>
      <Skeleton instances={1} skeleton={UserSkeleton} isLoading={isLoading}>
        <ul className="flex flex-col">
          {users.map((user) => (
            <UserDisplay key={user.id} user={user} />
          ))}
        </ul>
        <InviteUser />
      </Skeleton>
    </Card>
  );
}
