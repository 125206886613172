import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useMemo } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import api from '~/api';
import BackAction from '~/components/BackAction';
import EmptyDetails from '~/components/claim/EmptyDetails';
import ContentWrapper from '~/components/ContentWrapper';
import Page from '~/components/Page';
import RegistrationList from '~/components/registrations/RegistrationList';
import RegistrationSearch from '~/components/registrations/RegistrationSearch';
import useIsDesktop from '~/hooks/useIsDesktop';
import { usePathParams } from '~/hooks/usePathParams';

const searchParamsSchema = z.preprocess(
  (input) =>
    input instanceof URLSearchParams ?
      [...input.entries()].reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {},
      )
    : input,
  z.object({
    searchTerm: z.string().default(''),
    startDate: z.coerce.date().default(subDays(new Date(), 30)),
    endDate: z.coerce.date().default(new Date()),
  }),
);

const paramsSchema = z.object({
  storeId: z.string(),
  registrationId: z.string().optional(),
});

/** Represents a sidebar and main content layout, which intelligently shows both on desktop, while separating the sidebar list navigation and individual items on mobile. */
export default function RegistrationsLayout() {
  const [searchParams] = useSearchParams();
  const params = usePathParams(paramsSchema);
  const searchData = useMemo(
    () => searchParamsSchema.parse(searchParams),
    [searchParams],
  );

  /** Debounce data, so that the query parameter and derived state can be updated in real time, but the query key can be debounced. */
  const debouncedSearchData = useDebounce(searchData, 500);

  const { data: results, isLoading } = useQuery({
    refetchOnMount: true,
    throwOnError: true,
    queryKey: ['registrations', params.storeId, debouncedSearchData],
    queryFn: async () => {
      const kind = 'Registration';
      const { searchTerm, startDate, endDate } = debouncedSearchData;

      const resp = await api.store(params.storeId).search({
        kind,
        orderBy: 'desc',
        startDate: startOfDay(startDate).toISOString(),
        endDate: endOfDay(endDate).toISOString(),
        searchTerm,
      });

      if (resp.kind !== kind) {
        throw new Error('Invalid kind');
      }
      return resp;
    },
    retry: 3,
  });

  const isDesktop = useIsDesktop();
  const headline = `Registrations`;

  const registrations = results?.data ?? [];

  if (!isDesktop) {
    return (
      <Page headline={headline}>
        <ContentWrapper>
          {params.registrationId ?
            <>
              <BackAction.Link text="Registrations" />
              <div className="px-2">
                <Outlet />
              </div>
            </>
          : <>
              <RegistrationSearch
                searchTerm={searchData.searchTerm}
                dateRange={{
                  from: searchData.startDate,
                  to: searchData.endDate,
                }}
              />
              <RegistrationList
                registrations={registrations}
                isLoading={isLoading}
              />
            </>
          }
        </ContentWrapper>
      </Page>
    );
  }

  // desktop layout with list and details in a multi-column layout
  return (
    <Page headline={headline}>
      <ContentWrapper>
        <div className="grid grid-cols-4 items-start gap-4">
          {/*
           * // * top-20 is a magic value so that it doesn't slip behind the app layout navigation
           * // * z index is required to ensure any absolute positioned elements such as the date picker are above the app main content
           */}
          <ContentWrapper className="sticky top-20 z-10 w-full self-start">
            <RegistrationSearch
              searchTerm={searchData.searchTerm}
              dateRange={{
                from: searchData.startDate,
                to: searchData.endDate,
              }}
            />
            <RegistrationList
              registrations={registrations}
              isLoading={isLoading}
            />
          </ContentWrapper>
          <div className="col-span-3">
            {params.registrationId ?
              <Outlet />
            : <EmptyDetails
                title="No Registration Selected"
                description="Select a registration from the list to review."
                claimType="Registration"
              />
            }
          </div>
        </div>
      </ContentWrapper>
    </Page>
  );
}
