import { CrewClaimResolutionMethodEnum } from 'corso-types';
import { useState } from 'react';
import Alert from '~/components/Alert';
import Button from '~/components/Button';
import { NumberInput } from '~/components/field';
import {
  claimLineItemTotalPrice,
  createBaseReview,
  ReviewMetaStatus,
} from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { ResolutionModifierProps } from './types';

export default function MonetaryModifier({
  resolutionMethod,
  reviewLineItem,
  onChange,
}: ResolutionModifierProps & {
  resolutionMethod:
    | CrewClaimResolutionMethodEnum.refund
    | CrewClaimResolutionMethodEnum.giftCard;
}) {
  const totalPrice = claimLineItemTotalPrice(reviewLineItem.claimLineItem);
  const {
    storeUser: {
      store: { currencyCode, currencySymbol },
    },
  } = useMerchantContext();

  const [approvedAmount, setApprovedAmount] = useState(totalPrice);

  return (
    <>
      {approvedAmount > totalPrice && (
        // TODO have this warning for gift cards, and prevent approving more than requested for refunds to prevent errors
        <Alert
          variant="warning"
          message={
            resolutionMethod === CrewClaimResolutionMethodEnum.giftCard ?
              'Approving more than original value.'
            : 'Approving more than the original value, which may cause the refund to fail.'
          }
        />
      )}
      <NumberInput
        min={0}
        step={0.01}
        id="monetary-resolution-amount"
        label="How much would you like to approve?"
        addon={{
          insideStart: currencySymbol,
          insideEnd: currencyCode,
        }}
        value={approvedAmount}
        onChange={(event) => {
          setApprovedAmount(event.target.valueAsNumber);
        }}
      />
      <Button
        variant="primary"
        onClick={() =>
          onChange({
            claimLineItem: reviewLineItem.claimLineItem,
            noteToCustomer: reviewLineItem.noteToCustomer,
            reviewMetadata: {
              status: ReviewMetaStatus.approvingWithModification,
              approval: {
                // ? should quantity be available to be changed
                ...createBaseReview(reviewLineItem.claimLineItem),
                resolutionMethodEnum: resolutionMethod,
                requestedAmount: totalPrice,
                amount: approvedAmount,
              },
            },
          })
        }
      >
        Confirm
      </Button>
    </>
  );
}
